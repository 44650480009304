import React from "react";

const SessionExpired = React.lazy(()=> import('./SessionExpired'))

const SessionTimeOutRoute = {
  routeProps: {
    path: '/sessionExpired',
    component: SessionExpired,
  },
  name: "SessionExpired",
};

export default SessionTimeOutRoute;


