import { Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import interceptModules from "../Intercept";
import App from "../../../App";
import { Preloader } from "../../../bt-components/Preloader";

function InterceptApp() {
  return (
    <>
        <Switch>
          <React.Suspense fallback={<Preloader />}>
            {interceptModules.map((module) => (
              <Route {...module.routeProps} key={module.name} exact />
            ))}
          </React.Suspense>
        </Switch>
    </>
  );
}

export default InterceptApp;
