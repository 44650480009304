export function Load(pageName) {
   if (typeof window.BTOnlineAnalytics !== 'undefined') {
     const c = "'" + JSON.stringify(pageName) + "'";
     const d = JSON.parse(c.slice(1, c.length - 1));
     window.BTOnlineAnalytics['load'].apply(window.BTOnlineAnalytics, [
       d.i,
       d.m,
       'btcom',
       d.l || 'MYACCOUNT',
       d.s,
       d.p0,
       d.p1,
       d.p2,
       d.p21,
       d.e32,
       d.p12,
       d.e21
     ]);
   }
 }
 export function Click(event) {
  if (typeof window.BTOnlineAnalytics !== 'undefined') {
    const c = "'" + JSON.stringify(event) + "'";
    const d = JSON.parse(c.slice(1, c.length - 1));
    window.BTOnlineAnalytics['click'].apply(window.BTOnlineAnalytics, [
      d.i,
      d.m,
      'btcom',
      d.l || 'MYACCOUNT',
      d.s,
      d.p0,
      d.p1,
      d.type
    ]);
  }
}

