import { createContext, useContext } from "react";
import AssistedJourneyStore from "./assistedJourneyStore";

import CommonStore from "./commonStore";
import MergeRecoveryStore from "./mergeRecoveryStore";
import SelfRegistrationStore from "./selfRegistrationStore";
import UpdateContactStore from "./updateContactStore";

// import UserNameListStore from "./userNameListStore";

interface Store {
  commonStore: CommonStore;

  mergeRecoveryStore: MergeRecoveryStore;

  assistedJourneyStore: AssistedJourneyStore;
  updateContactStore:UpdateContactStore;
  selfRegistrationStore:SelfRegistrationStore;
}

export const store: Store = {
  commonStore: new CommonStore(),

  mergeRecoveryStore: new MergeRecoveryStore(),
  assistedJourneyStore: new AssistedJourneyStore(),

  updateContactStore:new UpdateContactStore(),
  selfRegistrationStore: new SelfRegistrationStore()
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
