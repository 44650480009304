import React from "react";

const Verification = React.lazy(() => import('./Verification'));

const VerificationRoute = {
    routeProps: {
        path: '/verification',
        component: Verification
    },
    name: "Verification"
};

export default VerificationRoute;