export const validate = (values: any) => {
  const errors: any = {}
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  // const re = /^[+]?\d+$/;
  const re = /^(?=.*[+0-9])[+0-9]+$/;
  let z =  re.test(values && values.mobileNumber);
  const char = values && values.mobileNumber && values.mobileNumber.slice(0,2);
  const char1 = values && values.mobileNumber && values.mobileNumber.slice(0,1);
  const char2 = values && values.mobileNumber && values.mobileNumber.slice(0,3);
  const count = (values && values.mobileNumber && values.mobileNumber.match(/\+/g) || []).length;
  let m = false;
  if(char === "07" || char === "44" || char1 === "7" || char2 === "+44"){
      m = true;
  }

  if (!values.title) {
    errors.title = "Title is required";
  }
  if (!values.firstName) {
    errors.firstName = "First name is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last name is required";
  }
  if (!values.primaryEMail) {
    errors.primaryEMail = "Email address is required";
  } else if (!regex.test(values.primaryEMail)) {
    errors.primaryEMail = "Your email address has been entered incorrectly";
  }
  // if (!values.confirmEmail) {
  //   errors.confirmEmail = "Email is required";
  // }
  // else if (!regex.test(values.confirmEmail)) {
  //   errors.confirmEmail = "Your email has been entered incorrectly.";
  // }
  //else if (values.primaryEMail !== values.confirmEmail) {
  else if (values.primaryEMail !== values.confirmEmail || (values.confirmEmail =='' && values.primaryEMail)) {
    errors.confirmEmail = "Primary email address does not match";
  }

  if (values.alternateEmail !== '' && !regex.test(values.alternateEmail)) {
    errors.alternateEmail = "Your email address has been entered incorrectly.";
  }
  // else if(values.alternateEmail !== "" && values.confirmAlternateEmail === ""){
  //   errors.confirmAlternateEmail = "Confirm Alternate mail id is not matching with email id";
  // }
 
  // if (values.confirmAlternateEmail !== "" && !regex.test(values.confirmAlternateEmail)) {
  //   errors.confirmAlternateEmail = "Your email address has been entered incorrectly";
  // }
  /*else if (values.alternateEmail && values.confirmAlternateEmail!=="" && !regex.test(values.confirmAlternateEmail)) {
    errors.confirmAlternateEmail = "Your email has been entered incorrectly.";
  }*/
  else if (values.alternateEmail !== values.confirmAlternateEmail || (values.confirmAlternateEmail =='' && values.alternateEmail)) {
    errors.confirmAlternateEmail = "Alternative email address does not match";
  }
  if (values.mobileNumber ==='') {
    errors.mobileNumber = "Please enter your mobile number.";
  } else if(values.mobileNumber !=='' && values.mobileNumber.length < 10){
    errors.mobileNumber = "Looks like some digits are missing. Please enter the complete number.";
  } else if((char === "07" && values.mobileNumber.length !== 11) || (char1 === "7" && values.mobileNumber.length !== 10) || (char === "44" && values.mobileNumber.length !== 12) || (char2 === "+44" && values.mobileNumber.length !== 13)){
    errors.mobileNumber = "Looks like some digits are missing. Please enter the complete number.";
  } else if((char1 === "+" && count > 1 ) || (char1 !== "+" && count>=1)){
    errors.mobileNumber = "Please enter a valid mobile number.";
    }
  // else if(values.mobileNumber !=='' && z === false){
  //   errors.mobileNumber = "Please enter a valid mobile number.";
  // }
   else if (values.mobileNumber !=='' && m === false) {
    errors.mobileNumber = "Looks like some digits are missing. Please enter the complete number.";
  }
  if (values.landLineNumber !=='' && (values.landLineNumber.length < 10 || values.landLineNumber.length > 11)) {
    errors.landLineNumber = "Your landline number is not valid";
  }
  return errors;
}