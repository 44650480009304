import React, { useState } from 'react';
import Step from './Step';
import "./ProgressBar.scss";
import { HeadingProposition } from '@arc-ui/components/dist/types/components/Heading/Heading';
export default function StepNavigation(props: any) {

  return (<div id="stepWrapper" className="stepWrapper">
    {props.labelArray?.map((item: any, index: any) => 
      <Step 
        key={index} 
        index={index} 
        label={item}
        selected={props.currentStep === index + 1}
        currentStep={props.currentStep}
        updateStep={props.updateStep}
        id={props.id}>
      </Step>)
    }



  </div>)
}