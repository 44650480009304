const DateService = {
    getDate: function(date?: any){
        let currDate = new Date(date);
        const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return currDate.getDate() + " " + months[currDate.getMonth()] + " " + currDate.getFullYear();
    },
    getCurrentDate: function(date?: any) {
        const dateValid = new Date(date);
        if (!isNaN(dateValid.getTime())) {
          return dateValid;
        }
        return new Date();
      },
    getHour: function(date: Date){
        return date.getHours();
    },
    getFirstDayOfMonth: function(date: Date){
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay;
    },
    getFirstDateOfWeek: function(date: Date){
        const d = new Date(date);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(d.setDate(diff));
    },
    addDays: function(date: Date, days: number) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy;
      }
    
}
export default DateService;