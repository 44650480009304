import React from "react";

const AddAccount = React.lazy(() => import('./AddAccount'));

const AddAccountRoute = {
    routeProps: {
        path: '/addAccount',
        component: AddAccount
    },
    name: "AddAccount"
};

export default AddAccountRoute;