import React from "react";
import "../../../../assets/styles/bt-reg.style.scss";

const UpdateContact= React.lazy(()=> import('./UpdateContact'))

export default {
  routeProps: {
    path: "/Account/Intercept/UpdateContact",
    component: UpdateContact,
  },

  name: "UpdateContact",
};
