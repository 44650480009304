import { makeAutoObservable, runInAction } from "mobx";
import { Load } from "../../analytics/analytics";
import mergeRecoveryJourney from "../api/recovery/mergeRecoveryJourney";
import Constants from "../models/constants";
import { RequestProfileWithDetails } from "../models/UserNameResponse";

import {
  ClientCharacteristicApiBody,
  UpdatePasswordApiBody,
  UserNameResponse,
  ValidateProfileApiResponse,
  ClientCharacteristicApiResponse,
  TriggerKCIResponse,
  UpdatePasswordApiResponse,
  CurrentUserNameResponse,
  SendVerificationMailResponse,
  ValidateSessionApiResponse,
} from "./../models/UserNameResponse";
import { store } from "./store";
import validateSession from "../../envandassets/assets/api/validateSession.json";

export default class MergeRecoveryStore {
  //  title = "Hello from Mobx!";

  editMode = false;
  loading = false;
  loadingInitial = false;

  getCurrentUserNameResponse: CurrentUserNameResponse | undefined = undefined;

  getProfileResponse: UserNameResponse | undefined = undefined;

  getValidateProfileApiResponse: ValidateProfileApiResponse | undefined =
    undefined;

  getClientCharacteristicApiResponse:
    | ClientCharacteristicApiResponse
    | undefined = undefined;

  getTriggerKciResponse: TriggerKCIResponse | undefined = undefined;
  sendVerificationMailResponse: SendVerificationMailResponse | undefined =
    undefined;

  getUpdatePasswordApiResponse: UpdatePasswordApiResponse | undefined =
    undefined;

  getValidateSessionApiResponse: ValidateSessionApiResponse | undefined =
    undefined;

  getProfilesError: string = "";

  validateProfilesError: string = "";

  validateClientCharacteristicApiError: string = "";

  triggerKciError: string = "";
  sendVerificationMailError: string = "";

  updatePasswordApiError: string = "";
  validateSessionApiError: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  // Merge Recovery Store Methods

  //2

  getProfiles = async (requestProfile: RequestProfileWithDetails) => {
    runInAction(() => {
      this.getProfileResponse = undefined;
      this.getProfilesError = "";
    });
    mergeRecoveryJourney
      .getProfiles(requestProfile)
      .then((m) => {
        runInAction(() => {
          store.commonStore.SessionId = m.sessionId;
          this.getProfileResponse = m;
        });
        store.commonStore.setAPIError("GETPROFILES", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.getProfilesError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "GETPROFILES",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  /************************************************************ */
  //3

  validateProfile = async (UserNameKey: string) => {
    runInAction(() => {
      this.getValidateProfileApiResponse = undefined;
      this.validateProfilesError = "";
    });
    mergeRecoveryJourney
      .validateProfile(UserNameKey)
      .then((m) => {
        let d = new Date();
        m?.result?.profileUnlockTime?.pinTimeout &&
          (m.result.profileUnlockTime.pinUnlockTime = new Date(
            d.getTime() + m?.result?.profileUnlockTime?.pinTimeout
          ));
        m?.result?.profileUnlockTime?.securityNumberTimeout &&
          (m.result.profileUnlockTime.securityNumberUnlockTime = new Date(
            d.getTime() + m?.result?.profileUnlockTime?.securityNumberTimeout
          ));
        m?.result?.profileUnlockTime?.securityQuestionTimeout &&
          (m.result.profileUnlockTime.securityQuestionUnlockTime = new Date(
            d.getTime() + m?.result?.profileUnlockTime?.securityQuestionTimeout
          ));
        runInAction(() => {
          this.getValidateProfileApiResponse = m;
        });
        store.commonStore.setAPIError("VALIDATEPROFILE", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.validateProfilesError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VALIDATEPROFILE",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  /************************************************************ */

  //4
  validateClientCharacteristic = async (
    clientCharacteristicApiBody: ClientCharacteristicApiBody
  ) => {
    runInAction(() => {
      this.getClientCharacteristicApiResponse = undefined;
      this.validateClientCharacteristicApiError = "";
    });
    mergeRecoveryJourney
      .validateClientCharacteristic(clientCharacteristicApiBody)
      .then((m) => {
        runInAction(() => {
          this.getClientCharacteristicApiResponse = m;
        });
        store.commonStore.setAPIError("VALIDATECLIENTCREDENTIAL", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.validateClientCharacteristicApiError =
            Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VALIDATECLIENTCREDENTIAL",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  /************************************************************ */
  //5
  updatePassword = async (updatePasswordApiBody: UpdatePasswordApiBody) => {
    runInAction(() => {
      this.getUpdatePasswordApiResponse = undefined;
      this.updatePasswordApiError = "";
    });
    mergeRecoveryJourney
      .updatePassword(updatePasswordApiBody)
      .then((m) => {
        runInAction(() => {
          this.getUpdatePasswordApiResponse = m;
        });
        store.commonStore.setAPIError("UPDATEPASSWORD", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.updatePasswordApiError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "UPDATEPASSWORD",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  /************************************************************ */

  //6

  triggerKci = async () => {
    runInAction(() => {
      this.getTriggerKciResponse = undefined;
      this.triggerKciError = "";
    });
    mergeRecoveryJourney
      .triggerKci()
      .then((m) => {
        runInAction(() => {
          this.getTriggerKciResponse = m;
        });
        store.commonStore.setAPIError("TRIGGERKCI", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.triggerKciError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "TRIGGERKCI",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  sendVerificationMail = async (values : Object) => {
    runInAction(() => {
      this.sendVerificationMailResponse = undefined;
      this.sendVerificationMailError = "";
    });
    mergeRecoveryJourney
      .sendVerificationMail(values)
      .then((m) => {
        runInAction(() => {
          this.sendVerificationMailResponse = m;
        });
        store.commonStore.setAPIError("SENDVERIFICATIONMAIL", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.sendVerificationMailError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "SENDVERIFICATIONMAIL",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  // validate session api

  validateSession = async () => {
    runInAction(() => {
      this.getValidateSessionApiResponse = undefined;
      this.validateSessionApiError = "";
    });
    mergeRecoveryJourney
      .validateSession()
      .then((m) => {
        runInAction(() => {
          this.getValidateSessionApiResponse = m;

          console.log(this.getValidateSessionApiResponse);
        });
        store.commonStore.setAPIError("VALIDATESESSION", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.validateSessionApiError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VALIDATESESSION",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };
}
