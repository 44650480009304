import React from "react";
import RegistrationComplete from "./RegistrationComplete";
//import RegistrationCompleteGrid from "./RegistrationCompleteGrid";

const RegistrationCompleteGrid= React.lazy(()=> import('./RegistrationCompleteGrid'))

export default {
  routeProps: {
    path: "/AssistedJourneyRegistrationComplete",
    component: RegistrationCompleteGrid,
  },

  name: "RegistrationComplete",
};
