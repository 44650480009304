import React from "react";
import SecurityQuestion from "./SecurityQuestion";
//import SecurityQuestionGrid from "./SecurityQuestionGrid";

const SecurityQuestionGrid= React.lazy(()=> import('./SecurityQuestionGrid'))

export default {
  routeProps: {
    path: "/AssistedJourneySecurityQuestion",
    component: SecurityQuestionGrid,
  },

  name: "SecurityQuestion",
};
