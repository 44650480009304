import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar/ProgressBar";

import assistedJourneyModules from "../AssistedJourneyPages/index";
import App from "../../../App";
import { GetInTouchModal } from "../../RecoveryJourneyPages/RecoveryJourneyComponents/GetInTouchModal";
import "./AssistedJourneyApp.scss";
import { Preloader } from "../../../bt-components/Preloader";
//const ProgressBar= React.lazy(()=> import('./ProgressBar/ProgressBar'));
import Header from "../../../app/layout/Header/Header";
import useAutoLogout from "../../../hooks/useAutoLogout";
import { TimeOutModal } from "./TimeOutModal";
import DecibelAnalytics from "../../../analytics/decibelAnalytics";
function AssistedJourneyApp() {

  function showBodyOverflow() {
    document.body.classList.remove("body-fixed");
  }

  const timeOutModalBody = () => {

    return (
      <span>
        <span className="bt-p modal-desc">
          Need more time? If not, your session will finish in {msToTime(idleTimeOut)} min.
        </span>
        <div className="timeOutModalBodyButtons">
          <span
            className="btn primary-btn yes-btn"
            onClick={() => {
              showBodyOverflow();
              toggleTimeOutModal();
            }}
          >
            {" "}
            Yes {" "}
          </span>
          <span
            className="btn primary-btn no-endNow-btn"
            onClick={() => {
              window.location.href = "../../"
            }}
          >
            {" "}
            No, end now {" "}
          </span>
        </div>
      </span>
    )
  }
  //  working on idle time scenario
  const [idleTimeOut, setIdleTimeOut] = useState(0);
  const timer = useAutoLogout(900);
  const labelArray = ["Security check", "Security number", "Password", "Complete"]

  const [timeOutModal, setTimeOutModal] = useState({
    open: false,
    timeOutModalPopupShown: false,
    title: "Your session ends soon",
    body: timeOutModalBody,
  });
  useEffect(() => {
    // console.log("TIMER: " + timer)
    if (timer <= 1) {
      setTimeOutModal({
        open: true,
        timeOutModalPopupShown: true,
        title: timeOutModal.title,
        body: timeOutModalBody,
      })
    }

  }, [timer])
  useEffect(() => {

    if (timeOutModal.open === true) {
      setIdleTimeOut(300000);


    }
  }, [timeOutModal.open])

  useEffect(() => {
    if (idleTimeOut === 1000 && window.location.pathname.includes("AssistedJourney")) {
      window.location.href = "../../"
    }

  }, [idleTimeOut])


  useEffect(() => {

    if (window.location.pathname.includes("AssistedJourney") && timeOutModal.open === true) {
      let myInterval = setInterval(() => {
        if (idleTimeOut >= 1000) {
          setIdleTimeOut(idleTimeOut - 1000);
          setTimeOutModal({
            open: true,
            timeOutModalPopupShown: true,
            title: timeOutModal.title,
            body: timeOutModalBody,
          });
        } else {

          clearInterval(myInterval);



        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }

  }, [idleTimeOut, timeOutModal]);





  const toggleTimeOutModal = () => {
    setIdleTimeOut(0);

    setTimeOutModal({
      ...timeOutModal,
      open: !timeOutModal.open,
      timeOutModalPopupShown: !timeOutModal.open || timeOutModal.timeOutModalPopupShown,
    });


  };

  const msToTime = (duration: any) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60);

    return (
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds)
    );
  };


  return (
    <>
      <>
        <BrowserRouter basename="/Account/Intercept">
          <div id="header-curve" className="header-hide">
            <Header />
          </div>
          <div id="progressBar" className="progress-bar-hide">
            <ProgressBar labelArray={labelArray} />
          </div>
          <DecibelAnalytics />
          <Switch>
            <React.Suspense fallback={<Preloader />}>
              {assistedJourneyModules.map((module) => (
                <Route {...module.routeProps} key={module.name} exact />
              ))}

              {(timeOutModal.open && window.location.pathname.includes("AssistedJourney")) && (
                <TimeOutModal
                  handleClose={toggleTimeOutModal}
                  title={timeOutModal.title}
                  getBody={timeOutModal.body}
                />
              )}
            </React.Suspense>
          </Switch>

        </BrowserRouter>
      </>
    </>
  );
}

export default AssistedJourneyApp;
