import React, { useState } from 'react';
import './style.scss';

interface Props {
    label: any;
    handleChange?:any;
    name?:any;
    selected?:boolean;
    checkboxData?:any;
}

const Checkbox = (props: Props) => {
    function handleChange(e:any) {
        
        props.handleChange(e.target.checked, props.name);
        
      }
    return (
        <>
             <div className={`${props.name === "contactme"?"":"outerdiv"}`}>
                {props.name === "contactme" ? 
                <label className="container">
                  <p className='textmargin'>{props.label}</p>
                  <input type="checkbox" name = {props.name} onChange={handleChange} checked={props.checkboxData && props.checkboxData.filter((data:any)=> data?.isChecked !== true).length < 1}/>
                  <span className="checkmark"></span>
                </label>:
                <label className="container">
                <p className='textmargin'>{props.label}</p>
                <input type="checkbox" name = {props.name} onChange={handleChange} checked={props.selected}/>
                <span className="checkmark"></span>
                </label>
                }
            </div>
        </>
    )
}

export default Checkbox;