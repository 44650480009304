import axios from "axios";
import { GetaccesstokenvordelResponse } from "../Models/RepairCentreModel";
// export default function getAuthTokenForRepairCenter(){
//     let baseurl = process.env.REACT_App_DashBoardUrl;
//     let tokenurl = process.env.REACT_APP_AccessTokenUrl;
//     let url = `${baseurl}${tokenurl}`;
//     return axios({
//         method: "get",
//         url: url,
//         headers: { 
//           "Content-type": "application/json",
//         }
//     })
// }
const getAuthTokenForRepairCenter = async (): Promise<GetaccesstokenvordelResponse> => {
  const instance = axios.create();
  //instance.defaults.baseURL = process.env.REACT_App_DashBoardUrl;

  const auth: {
    accessToken: string;

    deviceID: string;

    masterContactID: string;

    userID: string;

    contactID: string;
  } = (
    await instance.request({
      url: process.env.REACT_APP_AccessTokenUrl,
      method: "get",

      headers: {
        "Content-type": "application/json",
      },
    })
  ).data;
  if (auth && auth.accessToken && auth.deviceID) {
    sessionStorage.setItem('MyAccount', JSON.stringify({ 'accessToken': auth.accessToken, 'deviceID': auth.deviceID }));
  }

  return auth;
};
export { getAuthTokenForRepairCenter };