import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

const DecibelAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        let head = document.getElementsByTagName('head')[0];
        let decibelDetailsScript = document.getElementById("decibel-details");
        let decibelCollectionScript = document.getElementById("decibel-collection");
        let decibelInsightScript = document.getElementById("decibel-insight");
        let decibelTrackingScript = document.getElementById("decibel-tracking");
        let decibelScript = document.getElementById("decibel-script");
        var newScript;

        if (window.Decibel_Switch !== "ON") {
            decibelDetailsScript?.remove();
            decibelCollectionScript?.remove();
            decibelInsightScript?.remove();
            decibelTrackingScript?.remove();
            decibelScript?.remove();
            return;
        }

        if(!decibelDetailsScript) {
            // adding a script tag
            newScript= document.createElement('script');
            newScript.type = "text/javascript";
            newScript.id = "decibel-details";
            newScript.innerHTML = `// <![CDATA[
                (function(d,e,c,i,b,el,it) {
                  d._da_=d._da_||[];_da_.oldErr=d.onerror;_da_.err=[];
                  d.onerror=function(){_da_.err.push(arguments);_da_.oldErr&&_da_.oldErr.apply(d,Array.prototype.slice.call(arguments));};
                  d.DecibelInsight=b;d[b]=d[b]||function(){(d[b].q=d[b].q||[]).push(arguments);};
                  el=e.createElement(c),it=e.getElementsByTagName(c)[0];el.async=1;el.src=i;it.parentNode.insertBefore(el,it);el.id="decibel-script";
                })(window,document,'script','https://collection.decibelinsight.net/i/${process.env.REACT_APP_Decibel_AccountID}/${process.env.REACT_APP_Decibel_PropertyID}/di.js','decibelInsight');
              // ]]>`
            head.appendChild(newScript)
        }

        if(!decibelCollectionScript) {
            // adding a script tag
            newScript= document.createElement('link');
            newScript.id = "decibel-collection";
            newScript.href = "//collection.decibelinsight.net"
            head.appendChild(newScript)
        }

        if(!decibelInsightScript) {
            // adding a script tag
            newScript= document.createElement('link');
            newScript.id = "decibel-insight";
            newScript.href = "//cdn.decibelinsight.net"
            head.appendChild(newScript)
        }

        if(!decibelTrackingScript) {
            // adding a script tag
            newScript= document.createElement('script');
            newScript.type = "text/javascript";
            newScript.id = "decibel-tracking";
            newScript.innerHTML = `
            window.updateDecibelTracking = function (path) { 
                // ...your code
                console.log('pagePath', path)
                decibelInsight('trackPageView', path);
              }`
            head.appendChild(newScript)
        }

        let { pathname, hash, search } = window.location;
        pathname = pathname + hash + search;
        
        // update the hash paths to relative paths as decibel is unable to tract the hash routings in its portal
        if (pathname.includes('#/') || pathname.includes('/#')) {
            pathname = pathname.replace('#/', '/').replace('/#', '/');
        }

        if (pathname.includes('#')) {
            pathname = pathname.replace('#', '/');
        }
        window.updateDecibelTracking && window.updateDecibelTracking(`${pathname}`);
    }, [location])
    return (<></>);
}

export default DecibelAnalytics;