import React from "react";

const MyDetails = React.lazy(() => import('./MyDetails'));

const MyDetailsRoute = {
    routeProps: {
        path: '/myDetails',
        component: MyDetails
    },
    name: "MyDetails"
};

export default MyDetailsRoute;