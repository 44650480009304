import React from "react";
import SetNewPassword from "./SetNewPassword";
//import SetNewPasswordGrid from "./SetNewPasswordGrid";
const SetNewPasswordGrid= React.lazy(()=> import('./SetNewPasswordGrid'))

export default {
  routeProps: {
    path: "/AssistedJourneySetNewPassword",
    component: SetNewPasswordGrid,
  },

  name: "SetNewPassword",
};
