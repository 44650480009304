import { makeAutoObservable, runInAction } from "mobx";

import assistedJourney from "../api/assistedJourney/assistedJourney";

import Constants from "../models/assistedJourneyModels/AssistedJourneyConstants";

import triggerKci from "../../envandassets/assets/api/triggerKci.json";

import validateClientCharacteristic from "../../envandassets/assets/api/validateClientCharacteristic.json";

import validateClientCharacteristicFail1 from "../../envandassets/assets/api/validateClientCharacteristicFail1.json";

import validateClientCharacteristicFail2 from "../../envandassets/assets/api/validateClientCharacteristicFail2.json";

import validateClientCharacteristicProfileLocked from "../../envandassets/assets/api/validateClientCharacteristicProfileLocked.json";

import validateClientCharacteristicFail3 from "../../envandassets/assets/api/validateClientCharacteristicFail3.json";

import profileDetails from "../../envandassets/assets/api/profileDetails.json";
import resendOneOffCode from "../../envandassets/assets/api/resendOneOffCode.json";
import updateProfile from "../../envandassets/assets/api/updateProfile.json";


import {
  ClientCharacteristicApiBody,
  UpdatePasswordApiBody,
  ValidateProfileApiResponse,
  ClientCharacteristicApiResponse,
  TriggerKCIResponse,
  UpdatePasswordApiResponse,
  SendVerificationMailResponse,
  UserNameResponse,
  CurrentUserNameResponse,
  RequestProfileWithDetails,
  AuthRequestBody,
  AuthRequestApiResponse,
  ProfileDetailsApiResponse,
} from "./../models/assistedJourneyModels/AssistedJourneyModel";
import { store } from "./store";

export default class AssistedJourneyStore {
  //  title = "Hello from Mobx!";

  editMode = false;
  loading = false;
  loadingInitial = false;

  getProfileDetailsApiResponse: ProfileDetailsApiResponse |undefined = undefined;
  getAuthRequestApiResponse: AuthRequestApiResponse | undefined= undefined;
  
  getCurrentUserNameResponse: CurrentUserNameResponse | undefined = undefined;

  getProfileResponse: UserNameResponse | undefined = undefined;

  getValidateProfileApiResponse: ValidateProfileApiResponse | undefined =
    undefined;

  getClientCharacteristicApiResponse:
    | ClientCharacteristicApiResponse
    | undefined = undefined;

  getTriggerKciResponse: TriggerKCIResponse | undefined = undefined;
  sendVerificationMailResponse: SendVerificationMailResponse | undefined =
    undefined;

  getUpdatePasswordApiResponse: UpdatePasswordApiResponse | undefined =
    undefined;

  getProfilesError: string = "";

  validateProfilesError: string = "";
  validateProfileDetailsError: string="";

  validateClientCharacteristicApiError: string = "";

  triggerKciError: string = "";
  sendVerificationMailError: string = "";

  updatePasswordApiError: string = "";
authApiError:string ="";
  constructor() {
    makeAutoObservable(this);
  }

  // Assisted Journey Store Methods

  profileDetails= async () =>{
    runInAction(() => {
      this.getProfileDetailsApiResponse = undefined;
      this.validateProfileDetailsError = "";

    });
    assistedJourney.profileDetails().then((m)=> {
      runInAction(() => {
        store.commonStore.SessionId= m.sessionId;
console.log("session from api call : " +store.commonStore.SessionId);
        console.log("M FROM STORE: "+ m.result.isMobileNoPresent)
       this.getProfileDetailsApiResponse = m;
     //   this.getProfileDetailsApiResponse= profileDetails;
        console.log("M FROM STORE: "+ this.getProfileDetailsApiResponse.result.isMobileNoPresent)


      });
    //  store.commonStore.SessionId= m.sessionId;
      store.commonStore.setAPIError("VALIDATEPROFILEDETAILS", "");
    })
    .catch((err) => {
      runInAction(() => {
        this.validateProfileDetailsError = Constants["APIERRORMESSAGE"];
      });
      store.commonStore.setAPIError(
        "VALIDATEPROFILEDETAILS",
        Constants["APIERRORMESSAGE"]
      );
      console.log(err);
    
    });
  }

  //2

 
  /************************************************************ */
  //3


  /************************************************************ */

  //4
  validateClientCharacteristic = async (
    clientCharacteristicApiBody: ClientCharacteristicApiBody
  ) => {
    runInAction(() => {
      this.getClientCharacteristicApiResponse = undefined;
      this.validateClientCharacteristicApiError = "";
    });
    assistedJourney
      .validateClientCharacteristic(clientCharacteristicApiBody)
      .then((m) => {
        runInAction(() => {
           this.getClientCharacteristicApiResponse = m;

 /*  this.getClientCharacteristicApiResponse =
         validateClientCharacteristic; */

  /*   this.getClientCharacteristicApiResponse =
            validateClientCharacteristicFail1;    */
          
      /*  this.getClientCharacteristicApiResponse =
            validateClientCharacteristicFail2;   */
     /*  this.getClientCharacteristicApiResponse =
            validateClientCharacteristicFail3;  */

   /*  this.getClientCharacteristicApiResponse =
            validateClientCharacteristicProfileLocked;   */
        });
        store.commonStore.setAPIError("VALIDATECLIENTCREDENTIAL", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.validateClientCharacteristicApiError =
            Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VALIDATECLIENTCREDENTIAL",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  /************************************************************ */
  //5
  updatePassword = async (updatePasswordApiBody: UpdatePasswordApiBody) => {
    runInAction(() => {
      this.getUpdatePasswordApiResponse = undefined;
      this.updatePasswordApiError = "";
    });
    assistedJourney
      .updatePassword(updatePasswordApiBody)
      .then((m) => {
        runInAction(() => {
          this.getUpdatePasswordApiResponse = m;
          //this.getUpdatePasswordApiResponse= updateProfile;
        });
        store.commonStore.setAPIError("UPDATEPASSWORD", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.updatePasswordApiError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "UPDATEPASSWORD",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  /************************************************************ */

  //6

  triggerKci = async () => {
    runInAction(() => {
      this.getTriggerKciResponse = undefined;
      this.triggerKciError = "";
    });
    assistedJourney
      .triggerKci()
      .then((m) => {
        runInAction(() => {
     this.getTriggerKciResponse = m;
//this.getTriggerKciResponse=resendOneOffCode
         // this.getTriggerKciResponse = triggerKci;
        });
        store.commonStore.setAPIError("TRIGGERKCI", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.triggerKciError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "TRIGGERKCI",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };



  

}
