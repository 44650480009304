import axios, { AxiosError } from "axios";
import Constants from "../../models/constants";
import {
    GetConfigAttributeResponse,
    GetaccesstokenvordelResponse,
    GetUserDetailsResponse,
  
    UserAccountDetailsRequest,
    updateDetailsResponse,
    updateMarketingConsentsResponse,
    MarketingConsentRequest,
    updateContactInterceptAddSkipResponse
  } from "../../models/UpdateContactModels/UpdateContactModel";
  import { store } from "../../stores/store";
  import { getAuthTokenForUpdateContact } from "../authToken";
  import httpClient from "../agent";
import { authService } from "../../../features/modules/MSAL/AuthService";
import { DSPEnabledUser } from "../../../app/redux/api/Models/RepairCentreModel";

  const instance = axios.create();
  
  const getConfigAttributeUrl: any = process.env.REACT_APP_getConfigAttributeUrl;
  const getDSPEnabledUserUrl: any = process.env.REACT_APP_isDSPEnabledUserUrl_UpdateContact;

instance.defaults.baseURL = process.env.REACT_APP_UpdateDetailsAPIBaseURL;
const domainInstance = axios.create();

instance.interceptors.request.use(async (config) => {

  if(config.url?.includes("AccountSettings/UpdateProfileDetails")){
    const tokenObject = await getAuthTokenForUpdateContact();
    config.data.UserAccountDetailsViewModel.UserId = tokenObject.userID;
    return config;
  }
  else if(config.url == "/getaccesstokenvordel" || ( !(config.url?.includes(getUserDetailsUrl)))) 
  {return config}
  // const token = await getAuthToken();
else{
  let DisableReactSideSIPG: boolean = false;  
  let accessToken: string | null = "";
  let udid: string | null = "";
  let tokenObject:any;
 
  DisableReactSideSIPG = (
    await updateContactJourney.getConfigAttributes().then()
  ).DisableReactSideSIPG;
 
  //SAF Condition
  if (DisableReactSideSIPG) {
    
    const safTokenObject = await authService.getSAFAccessToken();
      if (safTokenObject !== null && safTokenObject !== undefined) {
        accessToken = safTokenObject?.accessToken;
        udid = "XY-XY-XY-XY"; 
      }  
  }
  else{
     tokenObject = await getAuthTokenForUpdateContact();
     udid = tokenObject?.deviceID;
  }
  
  config.headers = {
        "APIGW-Tracking-Header" : uuidv4(),
        "Content-Type": "application/json",
        "APIGW-Client-Id": process.env.REACT_APP_APGIW_ClientId,
        "UDID":udid
      }
  // config.headers = {
  //   "APIGW-Tracking-Header" : uuidv4(),
  //   // "APIGW-Tracking-Header": "test",
  //   "X-Forwarded-For": "10.100.28.235",
  //   "Content-Type": "application/json",

  //   "asp-session-id": store.commonStore.SessionId
  //     ? store.commonStore.SessionId
  //     : "",
  //   // "UserName":"firsttime@login.com"
  //  UserName: "btuser066@gmail.com",
  //  //  "UserName":"test9001@bt.com"
  // };
   //if (tokenObject.accessToken) config.headers.Authorization = `Bearer ${tokenObject.accessToken}`;

   if(DisableReactSideSIPG){   
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
   else {
    config.headers.Authorization = `Bearer ${tokenObject.accessToken}`;
   }

  return config;
    }
});

instance.interceptors.response.use(
  async (response) => {
    //await sleep(1000);
    store.commonStore.setAPIError("APIERROR", "");
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;

    store.commonStore.setAPIError("APIERROR", Constants["APIERRORMESSAGE"]);
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors?.hasOwnProperty("id")) {
          //  history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          //  toast.error(data);
        }
        break;

      case 401:
        //   toast.error("unauthorized");
        break;

      case 404:
        // history.push("/not-found");

        break;

      case 500:
        //    history.push("/server-error");
        break;
    }

    return Promise.reject(error);
  }
);
function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
  const updateUserDetailsUrl:any = process.env.REACT_APP_UpdateUserDetails;
  const  updateMarketingConsentsUrl:any =  process.env.REACT_APP_UpdateMarketingConsents;
  const updateContactInterceptAddSkipUrl:any =process.env.REACT_APP_updateContactInterceptAddSkip;
  const getUserDetailsUrl:any= process.env.REACT_APP_GetUserDetailsUrl;
  const updateContactJourney = {

    //
    getUserDetails : () => httpClient(instance).get<GetUserDetailsResponse>(getUserDetailsUrl),
    //getUserDetails : () => httpClient(instance).get<GetUserDetailsResponse>("/9e5d7d51-301b-4435-94b3-638c4c3cf03c"),
   updateUserDetails: (userAccountDetailsViewModel: UserAccountDetailsRequest) =>
    httpClient(domainInstance).post<updateDetailsResponse>(
      updateUserDetailsUrl,
      userAccountDetailsViewModel
    ),
    updateMarketingConsents: (updateMarketingConsentsModel: MarketingConsentRequest) =>
    httpClient(domainInstance).post<updateMarketingConsentsResponse>(
      updateMarketingConsentsUrl,
      updateMarketingConsentsModel
    ),
    UpdateContactInterceptAddSkip: () =>
    httpClient(domainInstance).post<updateContactInterceptAddSkipResponse>(
      updateContactInterceptAddSkipUrl,{}
    ),
    getConfigAttributes: () => httpClient(domainInstance).get<GetConfigAttributeResponse>(getConfigAttributeUrl),

    isDSPEnabledUser: () => httpClient(domainInstance).get<any>(getDSPEnabledUserUrl),
};
  
  export default updateContactJourney;
  