
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";




const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export function httpClient(instance: AxiosInstance) {
  interface Params {
    [key: string]: any;
};
  const httpClientInstance = {
    get: <T>(url: string,param?:Params) => instance.get<T>(url,param).then(responseBody),
    post: <T>(url: string, body: {}) =>
      instance.post<T>(url, body).then(responseBody),

    put: <T>(url: string, body: {}) =>
      instance.put<T>(url, body).then(responseBody),

    del: <T>(url: string) => instance.delete<T>(url).then(responseBody),
  };

  return httpClientInstance;
}



export default httpClient;
