import React, { useEffect, useState } from "react";
import "./ProgressBar.scss";
import { useHistory, withRouter } from "react-router-dom";
import { NotValidProfileModal } from "../../../RecoveryJourneyPages/RecoveryJourneyComponents/NotValidProfileModal";
import { toJS } from "mobx";
import { useStore } from "../../../../app/stores/store";
import  CancelAccountAccess  from "../../SelfRegistrationJourneyPages/AccountAccess/CancelAccountAccessPopup";
import { Load } from "../../../../analytics/analytics";

export default function Step(props: any) {
  // useHistory Hook//
  const history = useHistory();
  const [isCancelPopUp,setIsCancelPopUp] = useState(false);
  const { assistedJourneyStore,selfRegistrationStore } = useStore();
  const { profileDetails, getProfileDetailsApiResponse } = assistedJourneyStore;
  const { getValidateEmailApiResponse } = selfRegistrationStore;

  const [isSuccess, setIsSuccess] = useState(false);

  const toggleProgressBarStep = (currentStep: any) => {
    var stepBlocks = document.getElementsByClassName("stepBlock");

    var elements = document.body.getElementsByClassName("circle");
    if (elements !== undefined) {
      for (var i = 0; i < elements.length; i++) {
        if (i === props.index) {
          elements[props.index].className = "circle incomplete";
          stepBlocks[props.index].className = "stepBlock selected";
        }

        if (i > props.index) {
          if (
            elements[i].className === "circle  bt-icon bt-icon-tick completed"
          ) {
            elements[i].className = "circle  bt-icon bt-icon-tick completed";
            stepBlocks[i].className = "stepBlock completed";
          }

          if (elements[i].className === "circle incomplete") {
            elements[i].className = "circle";
            stepBlocks[i].className = "stepBlock";
          }
        }

        if (i < props.index) {
          let elementClass = elements[i].className;

          if (
            elements[i].className === "circle  bt-icon bt-icon-tick completed"
          ) {
            elements[i].className = "circle  bt-icon bt-icon-tick completed";
            stepBlocks[i].className = "stepBlock completed";
          }

          if (elements[i].className === "circle incomplete") {
            elements[i].className = "circle";
            stepBlocks[i].className = "stepBlock";
          }
        }
      }
    }
    //document.body.classList
    //document.body.classList.add("body-fixed", "enabledGetInTouchModal")
  };
  useEffect(() => {
    var completedCircles =
      document.body.getElementsByClassName("circle completed");
    if (completedCircles.length == 4) {
      for (var i = 0; i < 4; i++) {
        completedCircles[i].classList.add("disableClick");
      }
    }
  });

  return (<>
    <div className={"stepBlock"} id={props.id}>
      <div className={"circleWrapper"}>
        {/* <div id="circle" className={"circle" + (props.selected ?  " incomplete" : "")}  */}

        <div
          id="circle"
          className={"circle"}
          onClick={() => {
            props.updateStep(props.index + 1);

            var stepBlocks = document.getElementsByClassName("stepBlock");
            var stepLabels = document.getElementsByClassName("stepLabel");
            var elements = document.body.getElementsByClassName("circle");

            if (props.index === 0) {
                window.localStorage.setItem("naviIndex", "0");
              if (elements[0].className !== "circle") {
                // toggleProgressBarStep(props.index);
                // elements[3].className = "circle";
                // stepBlocks[3].className = "stepBlock";
                // elements[2].className = "circle";
                // stepBlocks[2].className = "stepBlock";
                // elements[1].className = "circle";
                // stepBlocks[1].className = "stepBlock";

              
                if (window.location.pathname.includes("registration")) {
                  if (window.location.pathname.includes("accountAccess") || window.location.pathname.includes("myDetails")) {
                    //setIsCancelPopUp(true)
                    document.body.classList.add("body-fixed", "enabledCancelAccountAccessModal");
                    Load({i:515, p0: ""});
                  }
                  else {
                    toggleProgressBarStep(props.index);
                    elements[3].className = "circle";
                    stepBlocks[3].className = "stepBlock";
                    elements[2].className = "circle";
                    stepBlocks[2].className = "stepBlock";
                    elements[1].className = "circle";
                    stepBlocks[1].className = "stepBlock";

                    history.push({
                      pathname: "/",
                      state: {
                        indirectPath: "progressbar",
                      }
                    });

                  }
                }
                else{
                if (getProfileDetailsApiResponse?.result.is1FA === true) {
                  toggleProgressBarStep(props.index);
                  elements[3].className = "circle";
                  stepBlocks[3].className = "stepBlock";
                  elements[2].className = "circle";
                  stepBlocks[2].className = "stepBlock";
                  elements[1].className = "circle";
                  stepBlocks[1].className = "stepBlock";
                  history.push({
                    pathname: "/AssistedJourneyOneoffCode",
                    state: {
                      profileUnlockTime: toJS(
                        getProfileDetailsApiResponse?.result.remainingUnlockTime
                      ),
                      isMobileNumberPresent: toJS(
                        getProfileDetailsApiResponse.result.isMobileNoPresent
                      ),
                    },
                  });
                } else {
                  history.push({
                    pathname: "/AssistedJourneySecurityQuestion",
                    state: {
                      profileUnlockTime: toJS(
                        getProfileDetailsApiResponse?.result.remainingUnlockTime
                      ),
                      isMobileNumberPresent: toJS(
                        getProfileDetailsApiResponse?.result.isMobileNoPresent
                      ),
                      securityQuestion: toJS(
                        getProfileDetailsApiResponse?.result.securityQuestion
                      ),
                    },
                  });
                }
              }
            }
            }

            if (props.index === 1) {
              window.localStorage.setItem("naviIndex", "1");
              if (elements[1].className !== "circle") {
                //debugger;
               // toggleProgressBarStep(props.index);
                // elements[3].className = "circle";
                // elements[2].className = "circle";
                // stepBlocks[3].className = "stepBlock";
                // stepBlocks[2].className = "stepBlock";

                if(window.location.pathname.includes("registration"))
                {
                    //  history.push({
                    //   pathname: "/addAccount",
                    //   state: {
                    //     indirectPath: "progressbar",
                    //   }
                    // });

                  if(window.location.pathname.includes("accountAccess") || window.location.pathname.includes("myDetails"))
                  {
                      //setIsCancelPopUp(true)
                      document.body.classList.add("body-fixed", "enabledCancelAccountAccessModal");
                      Load({i:515, p0: ""});
                  }
                  else
                  {
                    toggleProgressBarStep(props.index);
                    elements[3].className = "circle";
                    elements[2].className = "circle";
                    stepBlocks[3].className = "stepBlock";
                    stepBlocks[2].className = "stepBlock";

                    history.push({
                      pathname: "/addAccount",
                      state: {
                        indirectPath: "progressbar",
                      }
                    });
                  }

                  
                }
                else
                {
                  toggleProgressBarStep(props.index);
                  elements[3].className = "circle";
                  elements[2].className = "circle";
                  stepBlocks[3].className = "stepBlock";
                  stepBlocks[2].className = "stepBlock";
                  elements[1].className="circle incomplete";
                  stepBlocks[1].className="stepBlock selected";
                history.push({
                  pathname: "/AssistedJourneySetSecurityNumber",
                });
              }
            }
            }

            if (props.index === 2) {
              if (elements[2].className !== "circle") {
                toggleProgressBarStep(props.index);
                elements[3].className = "circle";
                stepBlocks[3].className = "stepBlock";
              
                if(window.location.pathname.includes("registration"))
                {
                  history.push({
                    pathname: "/accountAccess",
                    state: {
                     
                    }
                  });
                }
                else{
                  history.push({
                    pathname: "/AssistedJourneySetNewPassword",
                  });
                }
              }
            }

            if (props.index === 3) {
              if (elements[3].className !== "circle") {
                toggleProgressBarStep(props.index);
                history.push({
                  pathname: "/AssistedJourneyRegistrationComplete",
                });
              }
            }
          }}
        ></div>
      </div>
      <div className="stepLabel">
        <span className="bt-p">{props.label}</span>
      </div>
    </div>
    {/* {isCancelPopUp && <CancelAccountAccess history={history}/>} */}
    </>
  );
}
