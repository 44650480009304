import React, { useEffect, useState } from 'react';
import './style.scss';
interface Props {
  getTitleData?: any;
  selected?:any;
  formErrors?:any;
 }
  function Selectbox(props:Props) {
    const options = [
      {
        label: "",
        value: "",
        isSelected: false,
      },
      {
        label: "Mr",
        value: "Mr",
        isSelected: false
      },
      {
        label: "Ms",
        value: "Ms",
        isSelected:false
      },
      {
        label: "Mrs",
        value: "Mrs",
        isSelected:false
      },
      {
        label: "Miss",
        value: "Miss",
        isSelected: false
      },
      {
        label: "Dr",
        value: "Dr",
        isSelected:false
      },
      {
        label: "Professor",
        value: "Professor",
        isSelected:false
      },
    ];
    const [optionsData, setOptionData] = useState(options);
    const [selectclicked, setSelectclicked] = useState(false);
    useEffect(() => {
      let tempoptionData = optionsData.map((data:any)=>
      data.label === props.selected? {...data, isSelected:true}: data
      )
      setOptionData(tempoptionData);
    
    },[props.selected]);
    function handleChange(e:any){
     
      props.getTitleData(e.target.value);
    }
    function handleClick(){
      setSelectclicked(!selectclicked);
    }
    function handleBlur(){
      setSelectclicked(false);
    }
    return (
        <>
        <div>
        <select  className={`selecttitle ${selectclicked? "selecttitlechange":props.selected === "" && props.formErrors ?"invalid":""}`} onChange={handleChange} onClick={handleClick} onBlur={handleBlur}>
          {optionsData.map((option) => (
              <option value={option.value} selected={option.isSelected} style={{display:option.value === ""?"none":"block" }} defaultValue={option.value}>{option.label}</option>
            ))}
        </select>
        </div>
       
        </>
    );
  }
  export default Selectbox