// AuthService.ts
import { loginScopeRequest, msalConfig } from "./msalConfig";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticationResult } from "@azure/msal-common";
import { useState } from "react";
import promise from "redux-promise-middleware";

class AuthService {
  private readonly msalInstance: PublicClientApplication;
  private loginRedirectUrl = process.env.REACT_APP_Login_Msal_RedirectUrl;

  constructor() {   
    this.msalInstance = new PublicClientApplication(msalConfig);

    this.msalInstance.addEventCallback((event) => {
      console.info("EVENT Started!..");
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        console.info("Login Success!");
        this.msalInstance?.setActiveAccount(this.msalInstance.getAllAccounts()[0]);        
      }
    });
  }

  public getMsalInstance = (): PublicClientApplication => this.msalInstance;

  public relogin = (): void => {
    const currentLocation = window.location.href;
    window.location.href = `${this.loginRedirectUrl}/Account/LoginRedirect.aspx?target=${currentLocation}`;
  };

  public getAccessToken = async (): Promise<AuthenticationResult> =>
    this.msalInstance.acquireTokenSilent(loginScopeRequest);

  public getSAFAccessToken = async (): Promise<AuthenticationResult> => {    
    const accounts = this.msalInstance.getAllAccounts();
    let response: any;
    try {
      if (accounts && accounts.length > 0) {
        response = await this.msalInstance.acquireTokenSilent(
          loginScopeRequest
        );
       // console.log("SAF Access Token :", response);
      }
    } catch (error) {
      console.error("Error acquiring token:", error);
      throw error;
    }
    return response;
  };

  public logout = (): void => {
    this.msalInstance
      .logoutRedirect({
        idTokenHint: this.msalInstance.getAllAccounts()[0].idToken,
      })
      .catch((err) => console.error("Logout error:", err));
  };
}

export const authService = new AuthService();
