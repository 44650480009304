import { useHistory, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import Constants from "../../../../app/models/constants";
import { store, useStore } from "../../../../app/stores/store";

import { Preloader } from "../../../../bt-components/Preloader";
import { Warning } from "../../../../bt-components/Toastr/warning";
import "./SecurityCheck.scss";

interface IFormInputs {
  pin: string;
}

export default withRouter(
  observer(function SecurityCheck(props: any) {
    const [isLoading, setIsLoading] = useState(false);

    const [is1FA, setIs1FA] = useState(false);
    const history = useHistory();
    const { assistedJourneyStore, commonStore } = useStore();
    const { getAuthToken } = commonStore;

    const {
      profileDetails,
      getProfileDetailsApiResponse,
      validateProfilesError,
    } = assistedJourneyStore;
    const [isSuccess, setIsSuccess] = useState(false);
    const [isAPIFail, setIsAPIFail] = useState(false);
    const [apiCount, setIsApiCount] = useState(0);


    useEffect(() => {

      if (window.localStorage.getItem("asp-session-id") !== "") {
        window.localStorage.setItem("asp-session-id", "");
        store.commonStore.SessionId = "";
      }

    }, []);

    useEffect(() => {

      var element = document.getElementById("header-curve");
      if (element != null || element != undefined) {
        element.classList.remove("header-hide");
      }

    }, [])

    useEffect(() => {
      setIsLoading(true);
        getAuthToken();
    }, []);

    useEffect(() => {
      setIsLoading(true);
      setTimeout(() => {
        if (commonStore.accessToken !== "") {
          profileDetails();
        }
      }, 8000);
    }, []);

    useEffect(() => {
      if (commonStore.sessionExpired) {
       history.push("/AssistedJourneySessionExpired")
      }

    }, [commonStore.sessionExpired])
    useEffect(() => {
      
      if (getProfileDetailsApiResponse?.isSuccess) {
        setIsLoading(false);

        if (
          getProfileDetailsApiResponse.result.is1FA === true &&
          getProfileDetailsApiResponse.result.securityQuestion === null
        ) {
          history.push({
            pathname: "/AssistedJourneyOneOffCode",
            state: {
              profileUnlockTime: toJS(
                getProfileDetailsApiResponse?.result.remainingUnlockTime
              ),
              isMobileNumberPresent: toJS(
                getProfileDetailsApiResponse.result.isMobileNoPresent
              ),
            },
          });
        } else if (
          getProfileDetailsApiResponse.result.is1FA === false &&
          getProfileDetailsApiResponse.result.isMobileNoPresent === false &&
          getProfileDetailsApiResponse.result.securityQuestion === null
        ) {
          setIsLoading(false);

          callAPIFailModal(true);
          setTimeout(() => {
            history.push("/AssistedJourneyTechnicalError");
          }, 3000);
        } else if (
          getProfileDetailsApiResponse.result.securityQuestion !== null
        ) {
          setIsLoading(false);

          history.push({
            pathname: "/AssistedJourneySecurityQuestion",
            state: {
              profileUnlockTime: toJS(
                getProfileDetailsApiResponse?.result.remainingUnlockTime
              ),
              isMobileNumberPresent: toJS(
                getProfileDetailsApiResponse.result.isMobileNoPresent
              ),
              securityQuestion: toJS(
                getProfileDetailsApiResponse.result.securityQuestion
              ),
            },
          });
        } else {
          setIsLoading(false);

          callAPIFailModal(true);

          setTimeout(() => {
            history.push("/AssistedJourneyTechnicalError");
          }, 3000);
        }
      } else if (getProfileDetailsApiResponse?.isSuccess === false) {
        setIsLoading(false);

        callAPIFailModal(true);

        setTimeout(() => {
          history.push("/AssistedJourneyTechnicalError");
        }, 5000);
      }
    }, [getProfileDetailsApiResponse]);

    useEffect(() => {
      var progressBarElement =
        document.body.getElementsByClassName("progress-bar-hide");
      progressBarElement[0].className = "progressBar-grid";
    }, []);

    const callAPIFailModal = (showError: boolean) => {
      setIsAPIFail(showError);
    };
    return (
      <>
        <div className="securityCheck"></div>
        {isAPIFail && (
          <Warning
            closeWarning={callAPIFailModal}
            notificationTitle={Constants["APIERRORMESSAGE"]}
            showPopup={isAPIFail}
            popupType="ERROR"
          />
        )}

        {isLoading && <Preloader />}
      </>
    );
  })
);
