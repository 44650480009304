import { Route, Switch } from "react-router-dom";
import React from "react";
import reviewContractModule from "./index";
import { Preloader } from "../../../bt-components/Preloader";

function ReviewContractApp() {
  return (
    <>
        <Switch>
          <React.Suspense fallback={<Preloader />}>
              <Route {...reviewContractModule.routeProps} key={reviewContractModule.name} exact />
          </React.Suspense>
        </Switch>
    </>
  );
}

export default ReviewContractApp;
