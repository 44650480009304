import React, { useEffect, useState } from 'react'
import './style.scss';
import PageTitle from '../../../../bt-components/PageTitle';
import ErrorAlertComponent from '../../../../bt-components/ErrorAlert'
import Checkbox from '../UpdateContact/checkbox';
import ModalPopup from '../UpdateContact/modal/modal';
import Selectbox from "../UpdateContact/selectbox";
import PrimaryButton from '../../../../bt-components/PrimaryButton'
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { validate } from '../../../../Validator/Error-validation';
import ToolTipComponent from '../../../../bt-components/Tooltip';
import { toJS } from 'mobx'
import WhyImportant from './WhyImportant';
import LinkButton from '../../../../bt-components/LinkButton';
import { useAddClassToBody } from '../../../../hooks/addClassToBody.hook';
import ToastrSuccess from '../../../../bt-components/ToastrSuccess';
import ToastrWarning from '../../../../bt-components/ToastrWarning';
import { Preloader } from '../../../../bt-components/Preloader';
import { useRemoveElement } from '../../../../hooks/removeElement.hook';
import { useHideElement } from '../../../../hooks/hideElement.hook';
import { Click, Load } from "../../../../analytics/analytics";
import BTTooltip from '../../../../bt-components/TooltipComponent/Tooltip';
export const UpdateContact = () => {
    useAddClassToBody('bt-reg');
    const { updateContactStore, commonStore } = useStore();
    const {
        getUserDetails,
        getUserDetailsResp, updateUserDetails, getUpdateDetailsResp, getUpdateContactInterceptAddSkipResp, getMarketingConsentResp, updateMarketingConsents, UpdateContactInterceptAddSkip, isDSPEnabledUser, getIsDSPUser
    } = updateContactStore;
    const [formDataObj, setFormData] = useState({
        title: "",
        firstName: "",
        lastName: "",
        primaryEMail: "",
        confirmEmail: "",
        alternateEmail: "",
        confirmAlternateEmail: "",
        mobileNumber: "",
        landLineNumber: ""
    });
    const [initFormData, setInitFormData] = useState({
        title: "",
        firstName: "",
        lastName: "",
        primaryEMail: "",
        confirmEmail: "",
        alternateEmail: "",
        confirmAlternateEmail: "",
        mobileNumber: "",
        landLineNumber: ""
    });
    const [initialName, setInitialName] = useState("");
    const [checkboxData, setCheckboxData] = useState<any | null>([]);
    const [formErrors, setFormErrors] = useState<any>({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [showSkipLink, setShowSkipLink] = useState(true);
    let [consentList, setConsentList] = useState<string[]>([]);
    const [showmodal, setShowmodal] = useState(false);
    const [showToastrWarning, setShowToastrWarning] = useState(false);
    const [showToastrSuccess, setShowToastrSuccess] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    let [initContactOpt, setInitContactOpt] = useState<string[]>([]);
    const [updateDetailsFlag, setupdateDetailsFlag] = useState(false)
    const [any, setAny] = useState(false);
    let baseUrl = process.env.REACT_App_DashBoardUrl;
    const [counter, setCounter] = useState(0)
    const [checkvalid, setcheckvalid] = useState(false);
    const [errordata, seterrordata] = useState("")
    const [validsuccess, setvalidsuccess] = useState(true);
    const mobileValidation = (e: any) => {
        const re = /^[0-9\b]+$/;
        const { name, value } = e.target;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFormData({ ...formDataObj, [name]: value });
        }
    };

    function updateMarketPreRequest() {
        let isEmailPreferred = consentList.includes('EMAIL') ? true : false;
        let isSMSPreferred = consentList.includes('SMS') ? true : false;
        let isVoicePreferred = consentList.includes('VOICE') ? true : false;
        let isPostalPreferred = consentList.includes('POSTAL') ? true : false;
        return {
            "UserAccountDetailsViewModel":
            {
                "SpecialOfferPreference": isEmailPreferred ? true : false,
                "IsConsentPresent": true,
                "Consents": [
                    { "Type": "Email", "TextOnUI": "Email", "IsPreferred": isEmailPreferred },
                    { "Type": "SMS", "TextOnUI": "Text", "IsPreferred": isSMSPreferred },
                    { "Type": "Voice", "TextOnUI": "Phone", "IsPreferred": isVoicePreferred },
                    { "Type": "Postal", "TextOnUI": "Letter", "IsPreferred": isPostalPreferred }
                ]
            }
        };
    }

    function updateServiceNotificationReq() {
        return {
            "UserAccountDetailsViewModel":
            {
                Title: formDataObj.title,
                FirstName: formDataObj.firstName,
                LastName: formDataObj.lastName,
                MobileNumber: formDataObj.mobileNumber,
                LandLineNumber: formDataObj.landLineNumber,
                PrimaryEmailAddress: formDataObj.primaryEMail,
                AlternateEmailAddress: formDataObj.alternateEmail,
                UserId: "",
            }
        };
    }

    const confirmSubmit = async () => {
        let anycontactme = checkboxData && checkboxData.filter((data: any) => data?.isChecked !== true).length < 1;
        let mobilenumber = formDataObj.mobileNumber;
        let isMobilenoAvailable = false;
        if (mobilenumber === null || mobilenumber === undefined || mobilenumber === "") {
            isMobilenoAvailable = false;
        } else {
            isMobilenoAvailable = true;
        }

        Click({ i: 200, m: `Update my details - any:${anycontactme ? "yes" : "no"},email:${consentList.includes('EMAIL') ? "yes" : "no"},text:${consentList.includes('SMS') ? "yes" : "no"},phone:${consentList.includes('VOICE') ? "yes" : "no"},post:${consentList.includes('POSTAL') ? "yes" : "no"},mobile number:${isMobilenoAvailable ? "yes" : "no"}` });
        //remove this down
        //loadToastrSuccess();
        const errorsObj = await validate(formDataObj);
        setFormErrors(errorsObj);

        setIsSubmit(true);
        if (Object.keys(errorsObj).length === 0) {
            submitUserDetailsForm();
        }
    }

    function hidemodal() {
        setShowmodal(false);
        Click({ i: 200, m: "Update now" });
    }

    function hideToastrWarning() {
        setShowToastrWarning(false);
    }

    function loadToastrSuccess() {
        setisLoading(false);
        setShowToastrSuccess(true);
        Click({ i: 200, m: "Contact details updated", type: "loadtoaster" });
    }

    function getTitleData(title: any) {

        setFormData({ ...formDataObj, title: title })
    }
    useEffect(() => {
        // setTimeout(() => {
        //     let mobilenumber = formDataObj.mobileNumber;
        //     let isMobilenoAvailable = false;
        //     if(mobilenumber !== null || mobilenumber !== undefined || mobilenumber !== ""){
        //      isMobilenoAvailable = true;
        //     }else {
        //      isMobilenoAvailable = false;
        //     }
        //     Load({i: 200, p12: `email:${consentList.includes('EMAIL') ? "yes" : "no"},text:${consentList.includes('SMS') ? "yes" : "no"},phone:${consentList.includes('VOICE') ? "yes" : "no"},post:${consentList.includes('POSTAL') ? "yes" : "no"},phonenumber:${isMobilenoAvailable ? "yes" : "no"}`});
        // }, 3000);
    }, []);
    useEffect(() => {
        getUserDetails();
        setisLoading(true);
    }, [getUserDetails]);

    useEffect(() => {
        isDSPEnabledUser();
        setisLoading(true);
    }, [isDSPEnabledUser]);

    useEffect(() => {

        if (
            getUserDetailsResp &&
            getUserDetailsResp?.isSuccess === false
        ) {
            Load({
                i: 200,
                e32: "myaccount/user-details",
                p0: `${"myaccount/user-details"}:${getUserDetailsResp?.errorMessage}`,
            });
        }
        let DataObj = getUserDetailsResp;
        if (DataObj) {


            setInitialName(DataObj.result.FirstName);
            setInitContactOpt(toJS(DataObj.result.ConsentList));
            setConsentList(toJS(DataObj.result.ConsentList));

            if (DataObj.result.Intercepts.filter(function (e) { return e.Name === 'UpdateProfileIntercept'; }).length > 0) {
                setShowSkipLink(false);
            }
            console.log('DSP USER value--->'+getIsDSPUser);
            if(getIsDSPUser) {
                UpdateContactInterceptAddSkip();
                setisLoading(true);
                setShowmodal(false);
            }
            setFormData({
                ...formDataObj,
                title: DataObj.result.Title,
                firstName: DataObj.result.FirstName,
                lastName: DataObj.result.LastName,
                alternateEmail: DataObj.result.AlternativeEmailAddress,
                confirmAlternateEmail: DataObj.result.AlternativeEmailAddress,
                primaryEMail: DataObj.result.PrimaryEmailAddress,
                mobileNumber: DataObj.result.MobileNumber,
                landLineNumber: DataObj.result.LandlineNumber,
                confirmEmail: DataObj.result.PrimaryEmailAddress
            })
            setInitFormData({
                ...initFormData,
                title: DataObj.result.Title,
                firstName: DataObj.result.FirstName,
                lastName: DataObj.result.LastName,
                alternateEmail: DataObj.result.AlternativeEmailAddress,
                confirmAlternateEmail: DataObj.result.AlternativeEmailAddress,
                primaryEMail: DataObj.result.PrimaryEmailAddress,
                mobileNumber: DataObj.result.MobileNumber,
                landLineNumber: DataObj.result.LandlineNumber,
                confirmEmail: DataObj.result.PrimaryEmailAddress
            })
            let consentlistdetails: string | string[] = [];
            consentlistdetails = toJS(DataObj.result.ConsentList);
            setTimeout(() => {
                let mobilenumber = formDataObj.mobileNumber;
                let isMobilenoAvailable = false;
                if (mobilenumber !== null || mobilenumber !== undefined || mobilenumber !== "") {
                    isMobilenoAvailable = true;
                } else {
                    isMobilenoAvailable = false;
                }
                Load({ i: 200, p12: `email:${consentlistdetails.includes('EMAIL') ? "yes" : "no"},text:${consentlistdetails.includes('SMS') ? "yes" : "no"},phone:${consentlistdetails.includes('VOICE') ? "yes" : "no"},post:${consentlistdetails.includes('POSTAL') ? "yes" : "no"},mobile number:${isMobilenoAvailable ? "yes" : "no"}` });
            }, 3000);

            setisLoading(false);
        }
    }, [getUserDetailsResp]);

    useEffect(() => {
        if (consentList.length === 4) {
            //console.log("check consentList", consentList);
            let tempcheckboxData = checkboxData.map((data: any) =>
                consentList.includes(data.name) ? { ...data, isChecked: true } : data
            )
            setCheckboxData(tempcheckboxData);
        }
    }, [consentList])
    useEffect(() => {
        if ((getUpdateDetailsResp && getUpdateDetailsResp.Status !== 0)) {
            Load({
                i: 200,
                e32: "AccountSettings/UpdateProfileDetails",
                p0: `${"AccountSettings/UpdateProfileDetails"}:${getUpdateDetailsResp?.Message}`,
            });
        }


    }, [getUpdateDetailsResp]);

    useEffect(() => {
        let checkboxdata = [
            {
                name: "EMAIL",
                label: "Email",
            },
            {
                name: "SMS",
                label: "Text",

            },
            {
                name: "VOICE",
                label: "Phone",
            },
            {
                name: "POSTAL",
                label: "Post",
            }
        ];
        setCheckboxData(checkboxdata);
    }, [])

    const callUpdateInterceptSkip = () => {
        Click({ i: 200, m: "Later - Continue to dashboard" });
        UpdateContactInterceptAddSkip();
        setisLoading(true);
        setShowmodal(false);
    }

    useEffect(() => {
        if (getUpdateContactInterceptAddSkipResp && getUpdateContactInterceptAddSkipResp.Status === 0) {
            setisLoading(false);
            window.open(`/Account/Dashboard`, '_self')
        }
    }, [getUpdateContactInterceptAddSkipResp]);

    useEffect(() => {
        if ((getUpdateDetailsResp && getUpdateDetailsResp.Status === 0)) {
            setupdateDetailsFlag(true);
            updateMarketingConsents(updateMarketPreRequest());
        } else if ((getUpdateDetailsResp && getUpdateDetailsResp.Status === 1)) {
            setupdateDetailsFlag(false)
            updateMarketingConsents(updateMarketPreRequest());
        }
    }, [getUpdateDetailsResp]);

    useEffect(() => {
        if ((getMarketingConsentResp && getMarketingConsentResp.Status === 0) && updateDetailsFlag) {

            loadToastrSuccess();
            setTimeout(() => {
                setShowToastrSuccess(false);
                window.open(`/Account/Dashboard`, '_self')
            }, 3000);
        }
        else if ((getMarketingConsentResp && getMarketingConsentResp.Status === 1) || (getMarketingConsentResp && getMarketingConsentResp.Status === 0 && !updateDetailsFlag)) {
            setisLoading(false);
            setShowToastrWarning(true);
            Load({
                i: 200,
                e32: "AccountSettings/UpdateCommunicationPreferences",
                p0: `${"AccountSettings/UpdateCommunicationPreferences"}:${getMarketingConsentResp?.Message}`,
            });
        }
    }, [getMarketingConsentResp]);

    function checkboxdata(e: any, name: any) {

        if (name === 'contactme') {
            let tempcheckboxData = checkboxData.map((data: any) => { return { ...data, isChecked: e } }
            )
            setAny(e);
            checkboxData.map(function (data: any) {
                if (!(consentList.includes(data.name)) && e) {
                    consentList.push(data.name);
                }
                else if (!e) {
                    consentList = [];
                }
            })

            setConsentList(consentList);

            setCheckboxData(tempcheckboxData);

        }
        else {
            let tempcheckboxData = checkboxData.map((data: any) =>
                data.name === name ? { ...data, isChecked: e } : data
            )
            checkboxData.map(function (data: any) {
                if (data.name === name && e) {
                    consentList.push(name);
                }
                else if ((data.name === name && !e)) {
                    consentList = consentList.filter(function (val, arr, ind) {
                        return val !== name;
                    })
                }
            })

            for (let index = 0; index < tempcheckboxData.length; index++) {
                for (let i = 0; i < consentList.length; i++) {
                    if (tempcheckboxData[index].name === consentList[i]) {
                        tempcheckboxData[index].isChecked = true;
                    }
                }
            }
            setConsentList(consentList);
            setCheckboxData(tempcheckboxData);
        }
        setIsDisabled(JSON.stringify(consentList) === JSON.stringify(initContactOpt) ? true : false);
    }

    const submitUserDetailsForm = () => {
        setIsSubmit(true);
        setFormErrors(validate(formDataObj));
        updateUserDetails(updateServiceNotificationReq());
        setisLoading(true);
    }

    useEffect(() => {
        setIsDisabled(initContactOpt.length == consentList.length && initContactOpt.every(function (element, index) {
            return element == consentList[index]
        })
            && (JSON.stringify(formDataObj) === JSON.stringify(initFormData))
        );
    });

    // useRemoveElement('mainNavigation');
    useHideElement('mainNavigation');
    function continueDashboard() {
        setShowmodal(true);
        Click({ i: 200, m: "Continue to dashboard" });
    }

    useEffect(() => {
        if (!formDataObj.alternateEmail || !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.alternateEmail)) {
            setFormData({ ...formDataObj, confirmAlternateEmail: '' })
        }
    }, [formDataObj.alternateEmail]);

    useEffect(() => {
        if (!formDataObj.primaryEMail || !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.primaryEMail)) {
            setFormData({ ...formDataObj, confirmEmail: '' })
        }
    }, [formDataObj.primaryEMail]);
    function mobileNumberValidation(e: any) {
        let val = e.target.value;
        const re = /^(?=.*[+0-9])[+0-9]+$/;
        let z = re.test(e.target.value);
        const { name, value } = e.target;
        const char = val && val.slice(0, 2);
        const char1 = val && val.slice(0, 1);
        const char2 = val && val.slice(0, 3);
        const count = (val && val.match(/\+/g) || []).length;
        let m = false;
        if ((val === "" || re.test(e.target.value))) {
            if (char === "07" || char === "44" || char1 === "7" || char2 === "+44") {
                m = true;
            }
            if (val === "") {
                seterrordata("Please enter your mobile number.")
                setcheckvalid(true);
                setvalidsuccess(false);
            }
            if (re.test(e.target.value) && m === false && val && val !== "") {
                seterrordata("Your mobile number should start with 07 or +44.")
                setcheckvalid(true);
                setvalidsuccess(false);
            }
            else if ((char1 === "+" && count > 1) || (char1 !== "+" && count >= 1)) {
                seterrordata("Please enter a valid mobile number.")
                setcheckvalid(true);
                setvalidsuccess(false);
            }
            else if ((char2 === "+44" && val.length < 13) || (char === "44" && val.length < 12) ||
                (char === "07" && val.length < 11) ||
                (char1 === "7" && val.length < 10)) {
                seterrordata("Looks like some digits are missing. Please enter the complete number.")
                setcheckvalid(true);
                setvalidsuccess(false);
            } else if (re.test(e.target.value) && m === true && e.target.value.length >= 10) {
                setvalidsuccess(true);
                seterrordata("")
                setcheckvalid(false);
                setFormData({ ...formDataObj, [name]: value });
            }
            setFormData({ ...formDataObj, [name]: value });
        }
    }
    function maxLengthcheck(mob: any) {
        // return 10;
        const char = mob && mob.slice(0, 2);
        const char1 = mob && mob.slice(0, 1);
        const char2 = mob && mob.slice(0, 3);
        let m = 10;
        if (char === "07") {
            m = 11;
        } else if (char1 === "7") {
            m = 10;
        } else if (char === "44") {
            m = 12
        } else if (char2 === "+44") {
            m = 13
        }
        return m;
    }
    return (
        <>
            {getUserDetailsResp && !getIsDSPUser &&
                <form >
                    <div className="app-container content-holder">
                        <PageTitle title={[`${initialName && initialName}, keep your accounts up-to-date and secure`]} />
                        <div className="update-contact-wrp clearFloat">
                            <div className="form-wrapper">
                                <p className='mainLabel'>Contact details</p>
                                <hr className='hrline' />
                                <span className="bt-label">Select title</span>
                                <Selectbox getTitleData={getTitleData} selected={formDataObj && formDataObj.title} formErrors={formErrors.title} />
                                {formDataObj.title == '' && formErrors.title && <ErrorAlertComponent isError="true" dataTip={formErrors.title} >
                                </ErrorAlertComponent>}
                                <div className="bt-form-element first-name-el" >
                                    <span className="bt-label">First name</span>
                                    <div className="bt-bt-input-el-wrp pos-r">
                                        <input type="text" name="firstName" className={`bt-input-text ${formDataObj.firstName == '' && formErrors.firstName ? "invalid" : ""}`}
                                            onChange={e => setFormData({ ...formDataObj, firstName: e.target.value })}
                                            autoComplete="new-password"
                                            value={formDataObj.firstName} />
                                        {formDataObj.firstName == '' && formErrors.firstName && <ErrorAlertComponent isError="true" dataTip={formErrors.firstName} >
                                        </ErrorAlertComponent>}
                                    </div>
                                </div>
                                <div className="bt-form-element first-name-el" >
                                    <span className="bt-label">Last name</span>
                                    <div className="bt-input-el-wrp pos-r">
                                        <input type="text" name="lastName" className={`bt-input-text ${formDataObj.lastName == '' && formErrors.lastName ? "invalid" : ""}`}
                                            onChange={e => setFormData({ ...formDataObj, lastName: e.target.value })}
                                            autoComplete="new-password"
                                            value={formDataObj.lastName} />
                                        {formDataObj.lastName == '' && formErrors.lastName && <ErrorAlertComponent isError="true" dataTip={formErrors.lastName} >
                                        </ErrorAlertComponent>}
                                    </div>
                                </div>
                                <p className='mainLabel comm-pref'>Email addresses</p>
                                <hr className='hrline' />
                                <div className="bt-form-element first-name-el" >
                                    <span className="bt-label">Email address</span>
                                    <div className="bt-input-el-wrp pos-r">
                                        <input type="text" name="primaryEMail" className={`bt-input-text ${(formErrors.primaryEMail && !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.primaryEMail)) ? "invalid" : ""}`}
                                            onChange={e => setFormData({ ...formDataObj, primaryEMail: e.target.value })}
                                            autoComplete="new-password"
                                            value={formDataObj.primaryEMail} />
                                        <BTTooltip>


                                            <span
                                            >We use this to contact you with bill info, alerts, payments and changes to your account.</span>




                                        </BTTooltip>
                                        {/* <ToolTipComponent toolTip={"We use this to contact you with bill info, alerts, payments and changes to your account."} /> */}
                                        {(formErrors.primaryEMail && !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.primaryEMail)) && <ErrorAlertComponent isError="true" dataTip={formErrors.primaryEMail} >
                                        </ErrorAlertComponent>}
                                    </div>
                                </div>
                                <div className="bt-form-element first-name-el" >
                                    <span className="bt-label">Confirm email address</span>
                                    <div className="bt-input-el-wrp pos-r">
                                        <input type="text" name="confirmEmail" className={`bt-input-text ${new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.primaryEMail) && formDataObj.primaryEMail !== formDataObj.confirmEmail && formErrors.confirmEmail ? "invalid" : ""}`}
                                            disabled={!formDataObj.primaryEMail || !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.primaryEMail)}
                                            onChange={e => setFormData({ ...formDataObj, confirmEmail: e.target.value })}
                                            autoComplete="new-password"
                                            value={formDataObj.confirmEmail} />
                                        {new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.primaryEMail) && formDataObj.primaryEMail !== formDataObj.confirmEmail && formErrors.confirmEmail && <ErrorAlertComponent isError="true" dataTip={formErrors.confirmEmail} >
                                        </ErrorAlertComponent>}
                                    </div>
                                </div>
                                <div className="bt-form-element first-name-el" >
                                    <span className="bt-label">Alternative email address (optional)</span>
                                    <div className="bt-input-el-wrp pos-r">
                                        <input type="text" name="alternateEmail" className={`bt-input-text ${(formErrors.alternateEmail && !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.alternateEmail)) ? "invalid" : ""}`}
                                            onChange={e => setFormData({ ...formDataObj, alternateEmail: e.target.value })}
                                            autoComplete="new-password"
                                            value={formDataObj.alternateEmail} />
                                        <BTTooltip>


                                            <span
                                            >You can use this to access your account if you forget your login details.</span>




                                        </BTTooltip>
                                        {/* <ToolTipComponent toolTip={"You can use this to access your account if you forget your login details."} />
                                        {(formErrors.alternateEmail && !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.alternateEmail) )&& <ErrorAlertComponent isError="true" dataTip={formErrors.alternateEmail} >
                                        </ErrorAlertComponent>} */}
                                    </div>
                                </div>
                                <div className="bt-form-element first-name-el" >
                                    <span className="bt-label">Confirm alternative email address</span>
                                    <div className="bt-input-el-wrp pos-r">
                                        <input type="text" name="confirmAlternateEmail" className={`bt-input-text ${new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.alternateEmail) && formDataObj.alternateEmail !== formDataObj.confirmAlternateEmail && formErrors.confirmAlternateEmail ? "invalid" : ""}`}
                                            onChange={e => setFormData({ ...formDataObj, confirmAlternateEmail: e.target.value })}
                                            autoComplete="new-password"
                                            value={formDataObj.confirmAlternateEmail}
                                            disabled={!formDataObj.alternateEmail || !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.alternateEmail)} />
                                        {new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formDataObj.alternateEmail) && formDataObj.alternateEmail !== formDataObj.confirmAlternateEmail && formErrors.confirmAlternateEmail && <ErrorAlertComponent isError="true" dataTip={formErrors.confirmAlternateEmail} >
                                        </ErrorAlertComponent>}
                                    </div>

                                </div>
                                <p className='mainLabel comm-pref'>Phone numbers</p>
                                <hr className='hrline' />
                                <div className="bt-form-element first-name-el" >
                                    <span className="bt-label">Mobile number</span>
                                    <div className="bt-input-el-wrp pos-r">
                                        <input type="text" name="mobileNumber" className={`bt-input-text ${(formDataObj.mobileNumber === '' || formDataObj.mobileNumber.length < 10) && formErrors.mobileNumber || validsuccess === false ? "invalid" : ""}`}
                                            maxLength={maxLengthcheck(formDataObj.mobileNumber)}
                                            onChange={mobileNumberValidation}
                                            autoComplete="new-password"
                                            value={formDataObj.mobileNumber}
                                        />
                                        {validsuccess && <ToolTipComponent toolTip={"You use this for security, when adding billing accounts. It also helps you access your account if you forget your login details."} />}
                                        {(formDataObj.mobileNumber === '') && formErrors.mobileNumber ? <ErrorAlertComponent isError="true" dataTip={formErrors.mobileNumber} >
                                        </ErrorAlertComponent> : checkvalid ? <ErrorAlertComponent isError="true" dataTip={errordata} ></ErrorAlertComponent> : ""}
                                    </div>
                                </div>
                                <div className="bt-form-element first-name-el" >
                                    <span className="bt-label">Landline number (optional)</span>
                                    <div className="bt-input-el-wrp pos-r">
                                        <input type="text" name="landLineNumber" className={`bt-input-text ${(formDataObj.landLineNumber !== '' && (formDataObj.landLineNumber.length < 10 || formDataObj.mobileNumber.length > 11)) && formErrors.landLineNumber ? "invalid" : ""}`}
                                            maxLength={11} minLength={10}
                                            onChange={(e: any) => { mobileValidation(e); }}
                                            autoComplete="new-password"
                                            value={formDataObj.landLineNumber}
                                            disabled={formDataObj.mobileNumber ? false : true} />
                                        <BTTooltip>

                                            <span
                                            >We use this to contact you with offers, discounts and new product details, if you opt in below.</span>


                                        </BTTooltip>
                                        {/* <ToolTipComponent toolTip={"We use this to contact you with offers, discounts and new product details, if you opt in below."} /> */}
                                        {(formDataObj.landLineNumber !== '' && (formDataObj.landLineNumber.length < 10 || formDataObj.landLineNumber.length > 11)) && formErrors.landLineNumber && <ErrorAlertComponent isError="true" dataTip={formErrors.landLineNumber} >
                                        </ErrorAlertComponent>}
                                    </div>
                                </div>
                                <p className='mainLabel comm-pref'>Communication preferences</p>
                                <hr className='hrline com-pre-line' />
                                <div className="bt-form-element com-pre-el">
                                    <span className="bt-label">Be the first to know about exclusive deals and new products from BT and EE.</span>
                                    <Checkbox label={"Contact me on any of the below"} handleChange={checkboxdata} name={"contactme"} checkboxData={checkboxData} />
                                    <hr className='hrline' />
                                    {checkboxData.map((data: any) => (
                                        <Checkbox label={data.label} handleChange={checkboxdata} name={data.name} selected={(consentList.includes(data.name)) ? true : false} />
                                    ))}
                                </div>
                                <div className="uc-actoins-btns-wrp">
                                    <PrimaryButton disabled={isDisabled} onClick={confirmSubmit}>Update my details</PrimaryButton>
                                </div>
                            </div>
                            <WhyImportant />
                        </div>
                        {showSkipLink &&

                            <span className='thankyou'>Update my details later.<LinkButton onClick={continueDashboard}>Continue to dashboard</LinkButton></span>}

                    </div>
                </form>
            }
            {isLoading && <Preloader />}
            <ModalPopup showmodal={showmodal} hidemodal={hidemodal} redirectClick={() => callUpdateInterceptSkip()} />
            {showToastrSuccess ? <ToastrSuccess showToastr={showToastrSuccess}><span className="alert-msg title">Contact details updated</span>
                <span className="alert-msg desc">Thank you for taking the time to do this. Your data’s in safe hands and, you can change this an any point within Profile.</span> </ToastrSuccess> : ''}
            {showToastrWarning ? <ToastrWarning showToastr={showToastrWarning} hideToastrWarning={hideToastrWarning}> <span className="alert-msg title">An error occured</span>
                <span className="alert-msg desc">Sorry, your contact details were not updated at this time. Please try again within your Profile.</span> </ToastrWarning> : ''}
        </>
    )
}

export default observer(UpdateContact);
