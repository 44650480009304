import React from "react";
import SetSecurityNumber from "./SetSecurityNumber";
//import SetSecurityNumberGrid from "./SetSecurityNumberGrid";
const SetSecurityNumberGrid= React.lazy(()=> import('./SetSecurityNumberGrid'))

export default {
  routeProps: {
    path: "/AssistedJourneySetSecurityNumber",
    component: SetSecurityNumberGrid,
  },

  name: "SetSecurityNumber",
};
