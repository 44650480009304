import React from "react";

const ReviewContractPage = React.lazy(()=> import('./ReviewContractPage'))

export default {
  routeProps: {
    path: "/EECCComplience/:documentId",
    component: ReviewContractPage,
  },

  name: "ReviewContract",
};
