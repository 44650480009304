/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Click, Load } from "../../../analytics/analytics";
import { Preloader } from "../../../bt-components/Preloader";
import "../Styles/GetInTouchModal.scss";

declare global {
  interface Window {
    sprChat: (apiMethod: string, conversationContext?: unknown) => void;
  }
}

export const GetInTouchModal = () => {
  const [isLoading, setIsLoading] = useState(true);

  const chatMigrationSwitch = process.env.REACT_APP_ChatMigrationSwitch;
  let chatUrlCloudVoiceExpress: any;
  let chatUrlAllOtherServices: any;

  function openChatWindow(chatUrl: any, windowParams: any) {
    window.open(chatUrl, "_blank", windowParams);
  }


  return (
    <>
      <div className="get-in-touch-modal-wrp">
        <div className="get-in-touch-modal">
          <span
            className="close-icon"
            onClick={() => {
              document.body.classList.remove("body-fixed");
              document.body.classList.remove("enabledGetInTouchModal");
              if (window.location.pathname.includes("AssistedJourney")) {
                Click({
                  i: 136,
                  m: "Close",
                });
              }
              else {
                Click({
                  i: 102,
                  m: "Close",
                });
              }
            }}
          >
            Close <i className="bt-icon bt-icon-close"></i>
          </span>
          <div className="get-in-touch-modal-content">
            <span className="modal-title">Choose your service</span>
            <span className="bt-p modal-desc">
              So we can help you faster, please select the service you have.
            </span>

            {chatMigrationSwitch === "ON" && (
              <div
                id="LP_DIV_1634068824447"
                className="cloudVoiceExpressButton"
                data-qid="272"
                style={{ marginBottom: 25 }}
                onClick={() => {
                  if (window.location.pathname.includes("AssistedJourney")) {
                    Click({
                      i: 136,
                      m: "Cloud Voice Express",
                    });
                  }
                  else {
                    Click({
                      i: 102,
                      m: "Cloud Voice Express",
                    });
                  }

                  document.body.classList.remove(
                    "body-fixed",
                    "enabledGetInTouchModal"
                  );
                }}
              >

                <a
                  className="btn primary-btn cve-btn"
                  onClick={() => {

                    if (window.location.pathname.includes("AssistedJourney")) {
                      Click({
                        i: 136,
                        m: "Cloud Voice Express",
                      });
                    }
                    else {
                      Click({
                        i: 102,
                        m: "Cloud Voice Express",
                      });
                    }
                     window.sprChat('openNewConversation');
                    
                   
                  }}
                >
                  Cloud Voice Express
                </a>
              </div>
            )}

            {chatMigrationSwitch === "OFF" &&

              <a
                className="btn primary-btn cve-btn"
                onClick={() => {

                  if (window.location.pathname.includes("AssistedJourney")) {
                    Click({
                      i: 136,
                      m: "Cloud Voice Express",
                    });
                  }
                  else {
                    Click({
                      i: 102,
                      m: "Cloud Voice Express",
                    });
                  }

                  // openChatWindow(process.env.REACT_APP_ChatUrlCloudVoiceExpressNew, "")

                  openChatWindow(
                    process.env.REACT_APP_ChatUrlCloudVoiceExpressOld,
                    "location=yes,height=570,width=520, scrollbars=yes,status=yes"
                  );
                }}
              >
                Cloud Voice Express
              </a>
            }

            {chatMigrationSwitch === "ON" &&
              (

                <div id="LP_DIV_1634067762603" className="allOtherServicesButton" data-qid="153" onClick={() => {



                  document.body.classList.remove("body-fixed", "enabledGetInTouchModal")



                  if (window.location.pathname.includes("AssistedJourney")) {
                    Click({
                      i: 136,
                      m: "Close",
                    });
                  }
                  else {
                    Click({
                      i: 102,
                      m: "Close",
                    });
                  }
                }}>
                  <a
                  className="btn primary-btn other-service-btn"
                  onClick={() => {

                    if (window.location.pathname.includes("AssistedJourney")) {
                      Click({
                        i: 136,
                        m: "All other services",
                      });
                    }
                    else {
                      Click({
                        i: 102,
                        m: "All other services",
                      });
                    }

                    window.sprChat('openNewConversation');
                   
                  }}
                >
                  All other services
                </a>
                </div>

              )}

            {chatMigrationSwitch === "OFF" && (
              <>
                <a
                  className="btn primary-btn other-service-btn"
                  onClick={() => {

                    if (window.location.pathname.includes("AssistedJourney")) {
                      Click({
                        i: 136,
                        m: "All other services",
                      });
                    }
                    else {
                      Click({
                        i: 102,
                        m: "All other services",
                      });
                    }

                    openChatWindow(
                      process.env.REACT_APP_ChatUrlAllOtherServicesOld,
                      "location=yes,height=570,width=520, scrollbars=yes,status=yes"
                    );
                  }}
                >
                  All other services
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
