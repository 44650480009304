import { Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import myAccountModules from "../MyAccount";
import { Preloader } from "../../../bt-components/Preloader";
import { MainNavigation} from "./Utilities"

import "../../../assets/styles/bt-reg.style.scss";
const MyAccountApp = () => {
  return (

    <>
        <MainNavigation/>
        <Switch>
          <React.Suspense fallback={<Preloader />}>
          {(document.body.classList.contains('bt-reg')) && <MainNavigation/>}
            {myAccountModules.map((module) => (
                <Route {...module.routeProps} key={module.name} exact />
            ))}
          </React.Suspense>
        </Switch>
    </>
  );
}

export default MyAccountApp;
