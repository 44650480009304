import React from "react";

const Faults= React.lazy(()=> import('./Faults'))

export default {
  routeProps: {
    path: "/Account/Faults",
    component: Faults,
  },

  name: "Faults",
};
