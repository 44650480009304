import { store } from "./../stores/store";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};
const authReqBodyModB = {
  authenticate: "btone",
  username: "sqoetest3@bt.com",
  password: "Test@123",
  udid: "6372ba6e-753e-4cb0-8f4f-eb9691b4ac6c",
  client_id: "ffeec9b1-d717-45ad-b55f-1a96565b3dd4",
  client_secret: "9bd0d7e0-6331-4938-b98c-77d3e0763459",
  grant_type: "password",
  smsession: false,
  make: "Chrome",
  model: "83.0",
  os: "Windows XP",
  osv: "Microsoft Windows NT 6.3.9600.0",
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export function httpClient(instance: AxiosInstance) {
  const httpClientInstance = {
    get: <T>(url: string) => instance.get<T>(url).then(responseBody),
    getData: <T>(url: string,headers:{}) => instance.get<T>(url,headers).then(responseBody),
    getBlob: <T>(url: string) => instance.get<T>(url, { responseType: 'blob' }).then(responseBody),
    post: <T>(url: string, body: {}) =>
      instance.post<T>(url, body).then(responseBody),
      postData: <T>(url: string, body: {}, token : {}) =>
      instance.post<T>(url, body,token).then(responseBody),
    put: <T>(url: string, body: {}) =>
      instance.put<T>(url, body).then(responseBody),

    del: <T>(url: string) => instance.delete<T>(url).then(responseBody),
  };

  return httpClientInstance;
}

// function uuidv4() {
//   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
//     var r = (Math.random() * 16) | 0,
//       v = c == "x" ? r : (r & 0x3) | 0x8;
//     return v.toString(16);
//   });
// }

export default httpClient;
