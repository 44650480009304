import React from "react";
import { Click, Load } from "../../../../analytics/analytics";
import "./CancelAccountPopup.scss";

export const CancelAccount = (props :any) => {
  function closeModal(e: any) {
    e.preventDefault();
    document.body.classList.remove("body-fixed");
    document.body.classList.remove("enabledCancelAccountModal");
  }

  const toggleGetInTouchModal = () => {
    document.body.classList.add("body-fixed");
    document.body.classList.remove("enabledCancelAccountModal");
    document.body.classList.add("enabledGetInTouchModal");
    Load({i: 531,p0: ""});
  }

  return (
    <>
      <div className="cancel-account-modal-wrp">
        <div className="cancel-account-modal">
          <span
            className="close-icon"
            onClick={(e: any) => {
              closeModal(e);
              Click({
                i: 511,
                m: "Close",
              });
            }}
          >
            Close <i className="bt-icon bt-icon-close"></i>
          </span>
          <div className="cancel-account-modal-content">
            <span className="modal-title">Sure you want to cancel?</span>
            <span className="bt-p modal-desc">
            If you cancel, you'll need to start adding the account again to register.
            <div className="get-in-touch">
              <span className="bt-p get-in-touch-txt">Our team can help - &nbsp;
                <span className="bt-a cursor-p" onClick={() => {
                  Click({ i: 511, m: "Can’t remember your details. Chat with us" }); toggleGetInTouchModal();
                }}>
                  chat with us now<i className="bt-icon bt-icon-link-arrow"></i>
                </span>
              </span>
            </div>
            </span>
            <div className="buttonClass">
              <button
                  className="btn primary-btn"
                  onClick={(e: any) => {
                Click({ i: 511, m: "Yes, cancel" });
                  closeModal(e);
                  props.onConfirmCancel();
                  }}
              >
                  Yes, cancel
              </button>
              <button
                  type="button"
                  onClick={(e: any) => {
                Click({ i: 511, m: 'No, continue' });
                  closeModal(e);
                  }}
                  className="btn secondary-btn margin-left-18"
              >
                  No, continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
