import React from "react";

const EmailNotVerified= React.lazy(() => import('./EmailNotVerifiedPage'));

export default {
  routeProps: {
    path:'/VerifyEmail/EmailNotVerified',
    component: EmailNotVerified,
  },

  name: "EmailNotVerified",
};
