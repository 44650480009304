import React, { useState } from 'react';
import './style.scss';
import { useHistory } from "react-router-dom";
import PrimaryButton from '../../../../../bt-components/PrimaryButton';
import SecondaryButton from '../../../../../bt-components/SecondaryButton';
interface Props {
    hidemodal?:any;
    showmodal?:any;
  
    redirectClick:any;
}
function ModalPopup(props:Props){
    const history = useHistory();
    if(props.showmodal == true){
        document.body.classList.add('body-fixed');
    }
    function hidepopup(){
    props.hidemodal();
        document.body.classList.remove('body-fixed');
    }

    return(
        <>
        {props.showmodal === true ? 
         <div id="myModal" className="modalpopup">

         <div className="modalpopup-content">
             <p className='headingDetail'>Details and preferences not saved</p>
             <hr className='line' />
             <p className='bodyDetails'>If you want to keep your data accurate, update now. Or change it later in your My Account profile.</p>
        <div className="btn-update">
        <SecondaryButton onClick={() => hidepopup()}>Update now</SecondaryButton>
        <PrimaryButton onClick={props.redirectClick}>Later - continue to dashboard</PrimaryButton>
            </div> 
         </div>
 
         </div>:""
        }
       
        </>
    );



    
}
export default ModalPopup;
