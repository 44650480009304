import React from "react";
import SecurityCheck from "./SecurityCheck";
//const SecurityCheck= React.lazy(()=> import('./SecurityCheck'))


export default {
  routeProps: {
    path: "/AssistedJourney",
    component: SecurityCheck,
  },

  name: "SecurityCheck",
};
