import OneOffCode from "./OneOffCode";
import RegistrationComplete from "./RegistrationComplete";
import SecurityCheck from "./SecurityCheck";
import SecurityQuestion from "./SecurityQuestion";
import SetNewPassword from "./SetNewPassword";
import SetSecurityNumber from "./SetSecurityNumber";
import TechnicalErrorPage from "./TechnicalErrorPage";
import SessionTimeOut from "./SessionTimeOut";


export default [OneOffCode, RegistrationComplete, SecurityQuestion, SetSecurityNumber, SetNewPassword, TechnicalErrorPage, SecurityCheck,SessionTimeOut];
