import React from "react";



const SessionTimeOutGrid= React.lazy(()=> import('./SessionTimeOutGrid'))

export default {
  routeProps: {
    path: "/AssistedJourneySessionExpired",
    component: SessionTimeOutGrid,
  },

  name: "SessionTimeOut",
};
