import React from "react";
import TechnicalErrorPage from "./TechnicalErrorPage";
import TechnicalErrorPageGrid from "./TechnicalErrorPageGrid";

export default {
  routeProps: {
    path: "/AssistedJourneyTechnicalError",
    component: TechnicalErrorPageGrid,
  },

  name: "TechnicalErrorPage",
};
