import React from "react";
import OneOffCode from "./OneOffCode";
//import OneOffCodeGrid from "./OneOffCodeGrid";

const OneOffCodeGrid= React.lazy(()=> import('./OneOffCodeGrid'))

export default {
  routeProps: {
    path: "/AssistedJourneyOneOffCode",
    component: OneOffCodeGrid,
  },

  name: "OneOffCode",
};
