import { ADD_ERROR, CHANGE_CUG_SELECTION, FETCH_CLOSED_FAULTS, FETCH_OPEN_FAULTS, FETCH_USER_DETAILS, GET_CONFIG_ATTRIBUTES, IS_DSP_ENABLEDUSER,REPAIR_CENTRE_STATIC_DATA } from "../../action-types/repairCenter-action-type";

const initialState = {
    data: [],
    error: {}

};

const initialFaultState = {
    faults: [],
    loaded: {},

}
const initialCug = {
    selectedGroup: ""
}
const initialConfigAttributeState = {
    config: {}
}
export const fetchUserDetails = function (state = initialState, action: any) {
    switch (action.type) {
        case FETCH_USER_DETAILS:
            return {
                ...state,
                data: action.data,
                error: {},
            };
        default:
            return state;
    }
};
export const displayError = function (state = initialState, action: any) {
    switch (action.type) {
        case ADD_ERROR:
            return {
                ...state,
                data: action.data,
                error: action.error.message + Math.random(),
                fetcherror:action.error
            };
        default:
            return state;
    }
};

export const getConfigAttributes = function (state = initialConfigAttributeState, action: any) {
    switch (action.type) {
        case GET_CONFIG_ATTRIBUTES:
            return {
                ...state,
                config: action.data,
                error: {},
            };
        default:
            return state;
    }
};
// export const checkFaultNumber = function(state = initialState, action:any){
//     switch (action.type){
//         case CHECK_FAULT_VALID:
//             return {
//                 ...state,
//                 data:action.data,
//                 error:{},
//             };
//         default:
//             return state;
//     }
// };
export const fetchStaticData = function (state = initialState, action: any) {
    switch (action.type) {
        case REPAIR_CENTRE_STATIC_DATA:
            return {
                ...state,
                data: action.data,
                error: {},
            };
        default:
            return state;
    }
};
export const selectedGroup = function (state = initialCug, action: any) {
    switch (action.type) {
        case CHANGE_CUG_SELECTION:
            return { ...state, selectedGroup: action.data };
        default:
            return state;
    }
};
export const fetchFaults = function (state = initialFaultState, action: any) {
    switch (action.type) {

        case FETCH_OPEN_FAULTS:


            return { ...state, faults: state.faults.concat(action.data.result.Faults), openFaultsSuccess: (action.data.errorMessage == "")?true:false, loaded: { ...state.loaded, ...action.data.loaded }, opencode:action.data.code, openerrorMessage: action.data.errorMessage};
        case FETCH_CLOSED_FAULTS:

            return { ...state, faults: state.faults.concat(action.data.result.Faults), closedFaultsSuccess:  (action.data.errorMessage == "")?true:false, loaded: { ...state.loaded, ...action.data.loaded }, closecode:action.data.code, closeerrorMessage: action.data.errorMessage };
        default:
            return state;
    }
};

export const isDSPEnabledUser = function (state = initialState, action: any) {
    switch (action.type) {
        case IS_DSP_ENABLEDUSER:
            return {
                ...state,
                data: action.data,
                error: {},
            };
        default:
            return state;
    }
};
