import { makeAutoObservable, runInAction } from "mobx";
import selfRegistration from "../api/selfRegistration/selfRegistration";
import { ValidateEmailRequest, ValidateEmailApiResponse, ValidateBACApiResponse,
  ValidateOrderNumberApiResponse, ValidateBillRefApiResponse, CreatePINApiResponse, CreatePINApiBody,
  ValidatePINApiBody, ValidatePINApiResponse, RegisterUserResponse, RegisterUserRequest,
  PaperFreeFlagApiResponse, 
  MyDetails,
  AddAccountObject} from "../models/SelfRegistrationModels/SelfRegistrationModels";

import Constants from "../models/constants";
import { store } from "./store";


export default class SelfRegistrationStore {
  getValidateEmailApiResponse: ValidateEmailApiResponse |undefined  = undefined;
  validateEmailDetailsError: string="";

  getValidateOrderNumberApiResponse: ValidateOrderNumberApiResponse | undefined = undefined;
  validateOrderNumberDetailsError: string = "";

  getValidateBACApiResponse: ValidateBACApiResponse |undefined  = undefined;
  validateBACDetailsError: string="";

  getValidateBillRefApiResponse: ValidateBillRefApiResponse | undefined = undefined;
  ValidateBillRefApiError: string="";

  getCredentialCreatePinApiResponse: CreatePINApiResponse | undefined = undefined;
  credentialCreatePinApiError: string = "";

  getValidatePinApiResponse: ValidatePINApiResponse | undefined = undefined;
  validatePinApiError: string = "";

  getRegisterUserApiResponse: RegisterUserResponse | undefined = undefined;
  registerUserApiError: string = "";

  getPaperFreeFlagApiResponse: PaperFreeFlagApiResponse | undefined = undefined;
  getPaperFreeBillingApiResponse:PaperFreeFlagApiResponse | undefined = undefined;
  paperFreeFlagApiError: string = "";

  validatedEmail: string = "";
  currentApiFunc: string = "";
  validBACNumber: string = "";
  validBillref: string = "";

  myDetails: MyDetails | undefined = undefined;
  addAccountDetails:AddAccountObject | undefined = undefined;
  constructor() {
    makeAutoObservable(this);
  } 
  
  validBACNumberStore = (BACnumber:string) => {
    runInAction(() => {
      this.validBACNumber = "";
    });
    this.validBACNumber = BACnumber;
  };

  validBillRefStore = (Billref:string) => {
    runInAction(() => {
      this.validBillref = "";
    });
    this.validBillref = Billref;
  };
  
  validateEmail = async (validateEmailRequest : ValidateEmailRequest,token :object) => {
    runInAction(() => {
      this.getValidateEmailApiResponse = undefined;
      this.validateEmailDetailsError = "";

    });
    selfRegistration
      .validateEmail(validateEmailRequest,token)
      .then((m: any) => {
          runInAction(() => {
           store.commonStore.SessionId = m.sessionId;
          this.getValidateEmailApiResponse = m;
          this.validatedEmail = validateEmailRequest.UserName;
        });
        store.commonStore.setAPIError("VALIDATEEMail", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.validateEmailDetailsError = Constants["APIERRORMESSAGE"];
        });

        store.commonStore.setAPIError(
          "VALIDATEEMail",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  validateBAC = async (values : object,token : object) => {
    runInAction(() => {
      this.getValidateBACApiResponse = undefined;
      this.validateBACDetailsError = "";
      this.currentApiFunc = ""
    });
    selfRegistration
      .validateBAC(values,token)
      .then((m: any) => {
        runInAction(() => {
          this.getValidateBACApiResponse = m;
          this.currentApiFunc = "accountNumber"
        });
        store.commonStore.setAPIError("VALIDATEBAC", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.validateBACDetailsError = Constants["APIERRORMESSAGE"];
        });

        store.commonStore.setAPIError(
          "VALIDATEBAC",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };


  setAddAccountDetails = async (value :any) => {
   
    let valueObj = JSON.parse(JSON.stringify(value));
    
    runInAction(() => {
  
   this.addAccountDetails={  number:"",
  option:""}
 
    });
    this.addAccountDetails={  number:valueObj.number,
    option:valueObj.option
  }

  
}

setClearStoreData = async () => {
  runInAction(() => {
    this.validBACNumber = "";
    this.validBillref = "";
    this.getValidateBACApiResponse = undefined;
    this.getValidatePinApiResponse = undefined;
    this.getValidateOrderNumberApiResponse = undefined;

  });
  this.validBACNumber = "";
  this.validBillref = "";
  this.getValidateBACApiResponse = undefined;
  this.getValidatePinApiResponse = undefined;
  this.getValidateOrderNumberApiResponse = undefined;
}

  setMyDetails = async (value :any) => {
   
    let valueObj = JSON.parse(JSON.stringify(value));
    
    runInAction(() => {
  
   this.myDetails={  Title: "",
    FirstName : "",
    LastName : "",
    MobileNumber : "",
    AlternativeEmail : "",
    CommunicationPreferences : undefined,
    contactConsent:false,
    AcceptTermAndCondition : false}
 
    });
    this.myDetails={  Title: valueObj.Title,
    FirstName : valueObj.FirstName,
    LastName : valueObj.LastName,
    MobileNumber : valueObj.MobileNumber,
    AlternativeEmail : valueObj.AlternativeEmail,
    CommunicationPreferences : valueObj.CommunicationPreferences,
    contactConsent:valueObj.contactConsent,
    AcceptTermAndCondition : valueObj.AcceptTermAndCondition}
  
  }

  validateOrderNumber = async (value : object,token : object) => {
    runInAction(() => {
      this.getValidateOrderNumberApiResponse = undefined;
      this.validateOrderNumberDetailsError = "";
      this.currentApiFunc = ""
    });
    selfRegistration
      .validateOrderNumber(value,token)
      .then((m: any) => {
        runInAction(() => {
          this.getValidateOrderNumberApiResponse = m;
          this.currentApiFunc = "orderNumber"
        });
        store.commonStore.setAPIError("VALIDATEORDERNUMBER", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.validateOrderNumberDetailsError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VALIDATEORDERNUMBER",
          Constants["APIERRORMESSAGE"]
        );
        console.log("in catch", err);
      });
  };

  validateBillRef = async (value : object,token: object) => {
    runInAction(() => {
      this.getValidateBillRefApiResponse = undefined;
      this.ValidateBillRefApiError = "";
    });
    selfRegistration
      .validateBillRef(value,token)
      .then((m: any) => {
        runInAction(() => {
          this.getValidateBillRefApiResponse = m;
        });
        store.commonStore.setAPIError("VALIDATEBILLREF", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.ValidateBillRefApiError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VALIDATEBILLREF",
          Constants["APIERRORMESSAGE"]
        );
        console.log("in catch", err);
      });
  };

  credentialCreatePin = async (
    clientCharacteristicApiBody: CreatePINApiBody,
    token : object
  ) => {
    runInAction(() => {
      this.getCredentialCreatePinApiResponse = undefined;
      this.credentialCreatePinApiError = "";
    });
    selfRegistration
      .credentialCreatePin(clientCharacteristicApiBody,token)
      .then((m: any) => {
        runInAction(() => {
          this.getCredentialCreatePinApiResponse = m;
        });
        store.commonStore.setAPIError("CREDENTIALCREATEPIN", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.credentialCreatePinApiError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "CREDENTIALCREATEPIN",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  validatePin = async (
    validatePinApiBody: ValidatePINApiBody,
    token : object
  ) => {
    runInAction(() => {
      this.getValidatePinApiResponse = undefined;
      this.validatePinApiError = "";
    });
    selfRegistration
      .validatePin(validatePinApiBody,token)
      .then((m: any) => {
        runInAction(() => {
          this.getValidatePinApiResponse = m;
        });
        store.commonStore.setAPIError("VALIDATEPIN", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.validatePinApiError =
            Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VALIDATEPIN",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  registerUser = async (registerUserRequest : RegisterUserRequest,token :object) => {
    runInAction(() => {
      this.getRegisterUserApiResponse = undefined;
      this.registerUserApiError = "";
    });
    selfRegistration
      .registerUser(registerUserRequest,token)
      .then((m: any) => {
        runInAction(() => {
          this.getRegisterUserApiResponse = m;
        });
        store.commonStore.setAPIError("REGISTERUSER", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.registerUserApiError =
            Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "REGISTERUSER",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };

  getPaperFreeFlag = async (value : object,token : object) => {
    runInAction(() => {
      this.getPaperFreeFlagApiResponse = undefined;
      this.paperFreeFlagApiError = "";
    });
    selfRegistration
      .getPaperFreeFlag(value,token)
      .then((m: any) => {
        runInAction(() => {
         this.getPaperFreeFlagApiResponse = m ;
        });
        store.commonStore.setAPIError("VERIFYKCI", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.paperFreeFlagApiError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VERIFYKCI",
          Constants["APIERRORMESSAGE"]
        );
        console.log("in catch", err);
      });
  };

  getPaperFreeBilling = async (value : object,token:object) => {
    runInAction(() => {
      this.getPaperFreeBillingApiResponse = undefined;
      this.paperFreeFlagApiError = "";
    });
    selfRegistration
      .getPaperFreeBilling(value,token)
      .then((m: any) => {
        runInAction(() => {
       this.getPaperFreeBillingApiResponse = m ;
        });
        store.commonStore.setAPIError("VERIFYKCI", "");
      })
      .catch((err: any) => {
        runInAction(() => {
          this.paperFreeFlagApiError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VERIFYKCI",
          Constants["APIERRORMESSAGE"]
        );
        console.log("in catch", err);
      });
  };
}