/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Click, Load } from "../../../analytics/analytics";
import "./TimeOutModal.scss";

interface Props {
  handleClose?: any;
  title: string;
  getBody: any;
}

function showBodyOverflow() {
  document.body.classList.remove("body-fixed");
}
export const TimeOutModal = (props: Props) => {
  useEffect(() => {
    Load({
      i: 128,
    });
    hideBodyOverflow();
  }, []);

  const hideBodyOverflow = () => {
    document.body.classList.add("body-fixed");
  };
  return (
    <>
      <div className="timeOut-modal-wrp">
        <div className="timeOut-modal">
          <span
            className="close-icon"
            onClick={() => {
              showBodyOverflow();
              props.handleClose();

              Click({
                i: 128,
                m: "Close",
              });
            }}
          >
            <span>Close <i className="bt-icon bt-icon-close"></i></span>
          </span>
          <div className="timeOut-modal-content">
            <span className="modal-title">{props.title}</span>
            {props.getBody()}
          </div>
        </div>
      </div>
    </>
  );
};
