import React from "react";
import { observer } from "mobx-react-lite";
import { Click } from "../../../../analytics/analytics";
import { useStore } from "../../../../app/stores/store";
import "./CancelAccountAccessPopup.scss";


  const CancelAccountAccess = (props: any) => {
  const { selfRegistrationStore } = useStore();
  const {setClearStoreData} = selfRegistrationStore;

  function closeModal(e: any) {
    e.preventDefault();
    props.setShow(true);
    document.body.classList.remove("body-fixed");
    document.body.classList.remove("enabledCancelAccountAccessModal");

  }

  function onConfirmCancelClick(e: any) {
    e.preventDefault();
    props.setShow(false);
    var stepBlocks = document.getElementsByClassName("stepBlock");
    var elements = document.body.getElementsByClassName("circle");

    document.body.classList.remove("body-fixed");
    document.body.classList.remove("enabledCancelAccountAccessModal");

    let isUserValide = window.localStorage.getItem("naviIndex")
    if (isUserValide === "0") {
      elements[3].className = "circle";
      stepBlocks[3].className = "stepBlock";
      elements[2].className = "circle";
      stepBlocks[2].className = "stepBlock";
      elements[1].className = "circle";
      stepBlocks[1].className = "stepBlock";


      props.history.push({
        pathname: "/",
        state: {
          indirectPath: "progressbar",
        }
      });
 
    }
    else {
    
      props.history.push({
        pathname: "/addAccount",
        state: {
          indirectPath: "progressbar",
        }
      });
    }

  }


  return (
    <>
      <div className="cancel-accessaccount-modal-wrp">
        <div className="cancel-accessaccount-modal">
          <span
            className="close-icon"
            onClick={(e: any) => {
              Click({
                i: 515,
                m: "Close",
              });
              closeModal(e);
            }}
          >
            Close <i className="bt-icon bt-icon-close"></i>
          </span>
          <div className="cancel-accessaccount-modal-content">
            <span className="accessaccount-modal-title">Sorry, if you go back we won't save your details and we'll ask you to add your account again.</span>
            <div className="buttonClass">
              <button
                className="btn primary-btn"
                onClick={(e: any) => {
                  setClearStoreData();
                  Click({ i: 515, m: 'Go back anyway' });
                  onConfirmCancelClick(e);
                }}
              >
                Go back anyway
              </button>
              <button
                type="button"
                onClick={(e: any) => {
                  Click({ i: 515, m: 'continue' });
                  closeModal(e);
                }}
                className="btn secondary-btn margin-left-18"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default observer(CancelAccountAccess);
