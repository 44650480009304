import React, { useEffect, useState } from 'react'
import { Click } from '../../../../../analytics/analytics';
import { LinkButton, PrimaryButton } from '../../../../../bt-components';
import './style.scss';

interface faqDataObj {
    icon: string;
    label: string;
    hrefLink: string;
    linkTarget: string;
}
interface Props {
    faqData: faqDataObj[];
    sectionHeader?: string;
}
const FAQs = (props: Props) => {
    function analyticsClick(name:any){
        let rename = name && name.replace("?","")
        Click({ i: 400, m: `Quick fixes:${rename}` });
    }
    
    const openChat = () => {
        window.open('https://btbusiness.custhelp.com/app/home/#chat', '_blank');
        Click({ i: 400, m: `Chat now` });
    }

    return (
        <>
            <div className="bt-help-support-card">
                <div className="bt-card-title-wrp">
                    <span className="bt-card-title">{props.sectionHeader}</span>

                </div>
                <div className="bt-help-links-wrp">
                <div className="bt-help-links-col-wrp"> 
                    <div className="bt-col bt-col-left">

                        {props.faqData.filter((item, idx) => idx < Math.ceil(props.faqData.length / 2)).map((data: any) => (
                            <div
                                className="bt-help-lnk"
                                key={data.label}
                                onClick={()=>analyticsClick(data.label)}
                            >
                                <span className="bt-left_icon"> <i className={`ma-icon ${data.icon} `}></i></span>
                                <LinkButton target={data.linkTarget} hrefLink={data.hrefLink}>{data.label}</LinkButton>
                            </div>
                        ))}

                    </div>
                    <div className="bt-col bt-col-right">
                        {props.faqData.filter((item, idx) => idx >= Math.floor(props.faqData.length / 2)).map((data: any) => (
                            <div
                                className="bt-help-lnk"
                                key={data.label}
                                onClick={()=>analyticsClick(data.label)}
                            >
                                <span className="bt-left_icon"> <i className={`ma-icon ${data.icon} `}></i></span>
                                <LinkButton target={data.linkTarget} hrefLink={data.hrefLink}>{data.label}</LinkButton>
                            </div>
                        ))}
                    </div>
                    </div>
                    <div className="faq-help-support-wrp">
                        <span className="faq-help-support-title">Get a helping hand across all areas of your business, from billing to broadband.</span>
                        <div className="faq-help-support-btns">
                        <LinkButton  onClick={()=>analyticsClick("Help and support")} target="_blank" hrefLink="https://btbusiness.custhelp.com/app/home/">Help and support</LinkButton>
                        <PrimaryButton onClick={()=>{openChat()}}>Chat now</PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQs;