import React from "react";

const LoginDetails = React.lazy(() => import('./LoginDetails'));

const LoginRouteDetails = {
    routeProps: {
        path: '/',
        component: LoginDetails
    },
    name: "LoginDetails"
};

export default LoginRouteDetails;