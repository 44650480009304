import { Route, Switch } from "react-router-dom";
import React from "react";
import reviewContractT2RModule from "./index";
import { Preloader } from "../../../bt-components/Preloader";

function ReviewContractT2RApp() {
  return (
    <>
        <Switch>
          <React.Suspense fallback={<Preloader />}>
              <Route {...reviewContractT2RModule.routeProps} key={reviewContractT2RModule.name} exact />
          </React.Suspense>
        </Switch>
    </>
  );
}

export default ReviewContractT2RApp;
