import React, {useState } from 'react'
import { ManageAccountCard } from '../../../../../bt-components';
import './style.scss';

interface CardObj {
    title: string;
    content: any;
    icon?: string;
    linkLabel?:string;
    link?:string;
    linkTarget?:boolean;
}
interface Props {
    cardsData:CardObj[];
}
const ManageAccountSection = (props:Props) => {
    const [data, setData] = useState(props.cardsData);
    return (
        <>
            <div className="manage-account-wrp">
                <h2 className="section-title">Manage your account</h2>
                <div className="action-holder card_grid">
                    {data.map((card) => (
                        <ManageAccountCard key={card.title} title={card.title} content={card.content} icon={card.icon} linkLabel={card.linkLabel} link={card.link}/>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ManageAccountSection;