import { FC } from 'react';
import { loginScopeRequest, msalConfig } from './msalConfig';

import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';

interface PageGuardProps {
    children:any;
}

export const PageGuard: FC<PageGuardProps> = (props) => {
  useMsalAuthentication(InteractionType.Silent, loginScopeRequest);

  const { children } = props;
  const { inProgress } = useMsal();

  if (inProgress === InteractionStatus.SsoSilent) {
    return <div />;
  }

  return children;
};