import React, { useState } from "react";
import axios from "axios";
import { getAuthTokenForRepairCenter } from "../Authtoken/authTokenRepairCentre";
import httpClient from "./httpService";
import { checkFaultResponse, DSPEnabledUser, FaultModel, faultsResponse, GetConfigAttributeResponse, GetUserDetailsResponse, staticDataResponse } from "../Models/RepairCentreModel";
import { authService } from "../../../../features/modules/MSAL/AuthService";

// const getUserDetailsUrl:any= process.env.REACT_APP_GetUserDetailsUrl;
const getUserDetailsUrl: any = process.env.REACT_APP_userDetailsUrl;
const getConfigAttributeUrl: any = process.env.REACT_APP_getConfigAttributeUrl;
const checkFaultUrl: any = process.env.REACT_APP_CheckFaultValid;
const fetchStaticDataUrl: any = process.env.REACT_APP_RepairCentreStaticData;
const getOpenFaultsUrl: any = process.env.REACT_APP_OpenFaults;
const getClosedFaultsUrl: any = process.env.REACT_APP_ClosedFaults;
const faultApiUrl: any = process.env.REACT_APP_FaultApi;
const faultModelUrl:any=process.env.REACT_APP_getFaultModel;
const isDSPEnabledUserUrl: any = process.env.REACT_APP_isDSPEnabledUserUrl;
const environment = process.env.NODE_ENV
const axiosApg1Instance = axios.create();
const axiosApg2Instance = axios.create();
// axiosInstance.defaults.baseURL = process.env.REACT_App_DashBoardUrl;
const DomainInstance = axios.create();
// axiosInstance.defaults.baseURL = process.env.REACT_App_DashBoardUrl;
axiosApg1Instance.defaults.baseURL = process.env.REACT_APP_ApgUrl;
axiosApg2Instance.defaults.baseURL = process.env.REACT_APP_Apg_EE_Url;

let deviceID: any;
let bearerAccessToken: any;

axiosApg1Instance.interceptors.request.use(async (config) => {

  if (config.url == "/getaccesstokenvordel" || (!(config.url?.includes(getUserDetailsUrl)))) { return config }

  else {    
        //To call axiom get method
    const DisableReactSideSIPG =(await repairCentreJourney.getConfigAttributes().then()).DisableReactSideSIPG;    

     //Validation check for SAF Switch
    if (DisableReactSideSIPG != null && DisableReactSideSIPG !== undefined && DisableReactSideSIPG)
     {
      const safTokenObject = await authService.getSAFAccessToken();
 
      if (safTokenObject !== null && safTokenObject !== undefined) {
        bearerAccessToken = safTokenObject?.accessToken;
        console.log("SAF Access Token Received:");
        deviceID = "XXXXXXX";         

        if (bearerAccessToken !== null && bearerAccessToken !== undefined) 
          {
            console.log("log storage");
            sessionStorage.setItem("MySAFTokenAccount",JSON.stringify({accessToken: bearerAccessToken})
          );
        }
      }else{
        console.log("API - SAF Token Generation Error");
      }    
    } 
    else 
    {
      //SPIGW Token Response
      const tokenObject = await getAuthTokenForRepairCenter();
      deviceID = tokenObject?.deviceID;
      bearerAccessToken = tokenObject?.accessToken;
    }         

    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "Content-Type": "application/json",
      "APIGW-Client-Id": process.env.REACT_APP_APGIW_ClientId,
      UDID: deviceID,
    };

    if (bearerAccessToken !== null && bearerAccessToken !== undefined)
      config.headers.Authorization = `Bearer ${bearerAccessToken}`;
    return config;
  }
});
axiosApg2Instance.interceptors.request.use(async (config) => {

  if (config.url == "/getaccesstokenvordel") { return config }


  else {
    let tokenObject: any;   
    
    //To call axiom get method
    const DisableReactSideSIPG =(await repairCentreJourney.getConfigAttributes().then()).DisableReactSideSIPG;
    //Validation check for SAF Switch
    if (DisableReactSideSIPG != null && DisableReactSideSIPG !== undefined && DisableReactSideSIPG)
    {
      let sessionSAFTokenObject = JSON.parse(sessionStorage.getItem("MySAFTokenAccount") || "{}");

      if (sessionSAFTokenObject !== null && sessionSAFTokenObject !== undefined) {
        tokenObject = sessionSAFTokenObject;        
      } else {       
        tokenObject = await authService.getSAFAccessToken();        
      }

      if (tokenObject !== null && tokenObject !== undefined) {          
        // const ParseToken = JSON.parse(atob(tokenObject?.accessToken.split(".")[1]));
        // deviceID = ParseToken?.uuid;
        deviceID = "XXXXXXX";
        bearerAccessToken = tokenObject?.accessToken;
      } else {
        console.log("axiosApg2Instance - API - SAF Token Generation Error");
      }
    }else{
        //SPIGW Token Response
        let sessionTokenObject = JSON.parse(sessionStorage.getItem("MyAccount") || "{}");
        if (sessionTokenObject !== null || sessionTokenObject !== undefined) {
          tokenObject = sessionTokenObject;
        } else {
          tokenObject = await getAuthTokenForRepairCenter();
        }
        if (tokenObject !== null && tokenObject !== undefined) {

          deviceID = tokenObject.deviceID;
          bearerAccessToken = tokenObject.accessToken;
        }
    }

    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "Content-Type": "application/json",
      "APIGW-Client-Id": process.env.REACT_APP_APGIW_ClientId,
      UDID: deviceID,
    };

    if (bearerAccessToken != null && bearerAccessToken!==undefined)
      config.headers.Authorization = `Bearer ${bearerAccessToken}`;
    return config;
  }
});

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}



const repairCentreJourney = {

  //
  getUserDetails: () => httpClient(axiosApg1Instance).get<GetUserDetailsResponse>(getUserDetailsUrl),
  // checkFaultNumber : (param:string) => httpClient(axiosInstance).get<checkFaultResponse>(`${checkFaultUrl}?getenteredNumber=${param}`),
  checkFaultNumber: (param: string) => httpClient(DomainInstance).get<checkFaultResponse>(checkFaultUrl, {
    params: { "enteredNumber": param },
  }),
  fetchStaticData: () => httpClient(DomainInstance).get<staticDataResponse>(fetchStaticDataUrl, {
    params: { "v": "2022-05-09-17-23-34" },
  }),
  getConfigAttributes: () => httpClient(DomainInstance).get<GetConfigAttributeResponse>(getConfigAttributeUrl),
  // getOpenFaults:(params:any)=>httpClient(axiosInstance).get<faultsResponse>(getOpenFaultsUrl+"/"+params.groupKey,{
  //   params:{"pageSize":5,"index":1,"tabId":1}
  // }),
  // getClosedFaults:(params:any)=>httpClient(axiosInstance).get<faultsResponse>(getClosedFaultsUrl+"/"+params.groupKey,{
  //   params:{"pageSize":5,"index":1,"tabId":2}
  // })
  getOpenFaults: (groupData: any, tabId: any) =>
    httpClient(axiosApg2Instance)

      .get<faultsResponse>((environment == 'development') ? getOpenFaultsUrl : faultApiUrl.replace('{groupId}', groupData.selectedGroup.Key)

        .replace('{pageSize}', groupData.pageSize.toString())

        .replace('{index}', groupData.index.toString())

        .replace('{tabId}', tabId)),
  getClosedFaults: (groupData: any, tabId: any) =>
    httpClient(axiosApg2Instance)
      .get<faultsResponse>((environment == 'development') ? getClosedFaultsUrl : faultApiUrl.replace('{groupId}', groupData.selectedGroup.Key)

        .replace('{pageSize}', groupData.pageSize.toString())

        .replace('{index}', groupData.index.toString())

        .replace('{tabId}', tabId)),
  getFaultModel: (faultId:string,customerId:string) => 
  httpClient(DomainInstance)
  .get<FaultModel>((environment == 'development') ? faultModelUrl : faultModelUrl.replace('{faultId}',faultId).replace('{customerId}',customerId)),

  isDSPEnabledUser : () => httpClient(DomainInstance).get<DSPEnabledUser>(isDSPEnabledUserUrl),
};

// export default function Apicall(url:any, method:any){

//     return axios({
//         method: method,
//         url: url,
//         headers: { 
//         "APIGW-Tracking-Header" : uuidv4(),
//         "Content-Type": "application/json",
//         "APIGW-Client-Id": process.env.REACT_APP_APGIW_ClientId,
//         "UDID":accessTokendetails.deviceID,
//         Authorization : `Bearer ${accessTokendetails.accessToken}`
//         }
//     })
// }
export default repairCentreJourney;




