const Constants: { [key: string]: any; } = {
    "ATTEMPTFAILED1Passcode": "Incorrect passcode. Please try again or resend the passcode.",
    "ATTEMPTFAILED2Passcode": "That’s still not right. If it's wrong again, you can request a new passcode after 20 minutes.",
    "ATTEMPTFAILED1PIN": "Incorrect PIN. Please try again or tap Resend.",
    "ATTEMPTFAILED2PIN": "That’s still not right. If you get it wrong again, you’ll need to wait for 20 minutes.",
    "PROFILELOCKEDPIN": "Sorry, this was your last attempt, you’ll have to wait for 20 minutes before requesting a new PIN. Or you can contact us for further help.",
    "ATTEMPTFAILED1QUESTION": "The answer doesn’t match.",
    "ATTEMPTFAILED2QUESTION": "This still isn’t right. If you don’t enter it correctly a third time, you’ll need to wait 20 minutes to have another go.",
    "PROFILELOCKEDQUESTION": "Oh no, this was your last attempt, you’ll have to wait for 20 minutes before you can try again.",
    "ATTEMPTFAILED1NUMBER": "The numbers don’t match. Please try again.",
    "ATTEMPTFAILED2NUMBER": "This still isn’t right. If you don’t enter it correctly a third time, you’ll need to wait 20 minutes to have another go.",
    "PROFILELOCKEDNUMBER": "Oh no, this was your last attempt, you’ll have to wait for 20 minutes before you can try again.",
    "INVALIDACCOUNTNUMBER": "Sorry, we don’t recognise this account.",
    "INVALIDUSERNAME": "Sorry, seems that this name doesn’t match with the account number.",
    "INVALIDEMAIL": "Oops, something's not right. Please try again.",
    "SAMEPASSWORDERROR": "You can’t use your previous password. Please enter a new one.",
    "APIERRORMESSAGE" : "Sorry, something’s wrong with the system. Please try again.",
    "CONSUMERBAC": "Looks like you’re trying to recover the details for your home account?",
    "VERIFICATIONEMAILSENTSUCCESSTITLE1": "The email is on its way. Please check your inbox.",
    "VERIFICATIONEMAILSENTSUCCESSTITLE2": "The email's on its way",
    "VERIFICATIONEMAILSENTSUCCESSMESSAGE2": "If for some reason you don’t receive it, we can only send it one more time.",
    "VERIFICATIONEMAILSENTSUCCESSTITLE3": "The email is on its way.",
    "INVALIDACCOUNTNUMBERMSG": "Please check your name and account number again.",
    "INVALIDUSERNAMEMSG": "Please check your name and account number again.",
    "INVALIDEMAILMSG": "Sorry, we don’t recognise this email. Please check and try again.",
    "VERIFICATIONEMAILSENTSUCCESSMESSAGE3": "If you still don’t receive it, unfortunately we’re unable to verify you at this email address. Please try re-registering with a different one.",
    "VERIFICATIONEMAILSENTSUCCESSMESSAGE4": "If you still don’t receive it, unfortunately we can't verify you at this email address. Please try re-registering with a different one.",
    "VERIFICATIONEMAILSENTSUCCESSMESSAGE5": "If you still don't receive it, unfortunately we're unable to verify you at this email address. Please try re-registering with a different one.",
    "ERROR_503_MESSAGE": "The service is temporarily unavailable.",
    "ERROR_409_MESSAGE": "Resource conflict identified.",
    "ERROR_251_MESSAGE": "Response is already updated and can not be updated further.",
    "ERROR_551_MESSAGE": "Record does not exist.",
    "ERROR_504_MESSAGE": "Gateway timeout.",
    "ERROR_404_MESSAGE": "Page not found.",
    "ERROR_400_MESSAGE": "Bad request",
    "ERROR_403_MESSAGE": "Access denied.",
    "ERROR_405_MESSAGE": "Method not allowed",
    "ERROR_500_MESSAGE": "Internal server error",
    "ERROR_429_MESSAGE": "Too many requests",
    "ERROR_415_MESSAGE": "Unsupported media type",
    "ORDERNUMBERWARNINGMESSAGE" : "We don’t seem to have any contact details for you, so we can’t verify your account. Please get in touch to give us your details.",
    "RESENDSUCCESSMESSAGE": "We’ve just resent a PIN",
    "PINNOTSENTTITLE": "PIN wasn’t sent",
    "PINNOTSENTMESSAGE1" : "Sorry, something’s gone wrong. Please try requesting it again.",
    "PINNOTSENTMESSAGE2" : "Sorry we’re still unable to send the PIN. Please get in touch.",
    "IDLETIMEOUT": 900,
    "REMAININGTIMEOUT": 300000,
    "": ""
}

export default Constants
