import React from "react";
import { Click } from "../../../../analytics/analytics";
import { useLocation } from "react-router-dom";
import "./PaperFreePopup.scss";

export const PaperFreeBillingCancleAccount = () => {
  const location = useLocation();
  const c = location.pathname;
  const code = c.substring(c.lastIndexOf('/') + 1);

  function closeModal(e: any) {
    e.preventDefault();
    document.body.classList.remove("body-fixed");
    document.body.classList.remove("enablePaperFreeBillingModal");
  }

  return (
    <>
      <div className="cancel-freepopup-modal-wrp">
        <div className="cancel-freepopup-modal">
          <span
            className="close-icon"
            onClick={(e: any) => {
              Click({ i: 602, m: "Close" });
              closeModal(e);
            }}
          >
            Close <i className="bt-icon bt-icon-close"></i>
          </span>
          <div className="cancel-freepopup-modal-content">
            <span className="modal-title">Good decision. This account's now paper-free.</span>
            <div className="buttonClass">
              <button
                className="btn primary-btn"
                onClick={(e: any) => {
                  Click({ i: 602, m: "Continue to My Account" });
                  closeModal(e);
                  window.location.href = `/Account/LoginRedirect.aspx`;
                }}
              >
                 Continue to My Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
