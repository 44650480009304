const RoutingConstants: { [key: string]: any; } = {
    "RECOVERLOGININT": "/RecoverLoginDetails#/RecoverLoginIntPage",
    "RECOVERLOGINDETAILS": "/RecoverLoginDetails",
    "RECOVERLOGIN": "/RecoverUsername#/recoverLogin",
    "RECOVERACCOUNT": "/RecoverUsername#/recoverAccount",
    "GETPROFILES": "/RecoverUsername#/getProfiles",
    "PINVERIFICATION": "/RecoverUsername#/pinVerification",
    "RECOVERLOGINSUCCESS": "/RecoverUsername#/recoverLoginSuccess",
    "TECHNICALERROR": "/RecoverUsername#/technicalError",
    "SESSIONEXPIRED": "/RecoverUsername/SessionExpired",
    "PASSWORDSUCCESS": "/RecoverUsername#/PasswordSuccessfullyChanged",
    "SECURITYNUMBER": "/RecoverUsername#/securityNumber",
    "CANTREMEMBERSN": "/RecoverUsername#/CantRememberSN",
    "HUB":"/hub",
    "": ""
}

export default RoutingConstants
