import React, { useState } from 'react'

const WhyImportant = () => {
    const [showAccordian, setshowAccordian] = useState(false); 
    const handleAccordian = () => {
        setshowAccordian(showAccordian => !showAccordian);
     }
    return (
        <>
        <div className="why-important-cnt">
            <span className={`textstyle mobile-only accor-title-txt-wrp ${showAccordian === true? "accor-expand":""}`} onClick={()=> {handleAccordian();}}><span className="accor-title-txt">Why is this important?</span><i className="bt-arrow-down bt-icon black"></i></span>
            <div className={`cnt-wrp ${showAccordian === true? "accor-expand":""}`}>
                <span className='textstyle none-mobile'><i className="ma-icon ma-info md"></i>Why is this important?</span>
                <p className='paragraphstyle'>Having accurate data means we can give you our best customer experience. It also ensures you receive security updates, billing updates and service info.</p>
                <span className='textstyle'><i className="ma-icon ma-cake md"></i>Our gift to you</span>
                <p className='paragraphstyle'>Be the first to know about exclusive offers, discounts and new products that could improve your business. </p>
                <span className='textstyle'><i className="ma-icon ma-thumbsUp md"></i>You’re in the driving seat</span>
                <p className='paragraphstyle'>We won’t overload you with general info. And you choose how you hear from us - by email, text, phone or post.</p>
                <p className='paragraphstyle'>If you change your mind you can opt-out in My Account Profile, or tap “Unsubscribe” from any email or text.</p>
                <span className='textstyle'><i className="ma-icon ma-shield md"></i>Your data's in safe hands</span>
                <p className='paragraphstyle'>Your personal info is a big deal to us. It’s stored in our UK data warehouses and secured to the highest corporate standards.</p>
            </div>
        </div>
        </>
    )
}

export default WhyImportant;