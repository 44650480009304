// Define MSAL configuration
import { Configuration, RedirectRequest } from '@azure/msal-browser';

const ClientId = process.env.REACT_APP_SAF_Msal_ClientId || ""; // Replace with your actual client ID
const Authority = process.env.REACT_APP_SAF_Msal_Authority; // Replace with your actual tenant ID (//Domain Name-TenantId-SignInPolicy)
const KnownAuthorities = process.env.REACT_APP_SAF_Msal_KnownAuthorities || "";
const RedirectUri= process.env.REACT_APP_SAF_Repair_RedirectUri; // Replace with your actual redirect URI
const Scope = process.env.REACT_APP_SAF_Msal_Scope || "";


//const USER_SCOPES = Scope || '';

export const msalConfig: Configuration  = {
    auth: {      
      clientId: ClientId, // Replace with your actual client ID
      authority: Authority, // Replace with your actual tenant ID (//Domain Name-TenantId-SignInPolicy)
      knownAuthorities:[KnownAuthorities],
      redirectUri: RedirectUri // Replace with your actual redirect URI
    // redirectUri: 'http://localhost:3000'
     // redirectUri: 'https://eupslocal.com'
     // redirectUri: 'https://eupslocal.com/Account/RepairCentre'
    },
    cache: {
      cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    },
  };

  export const loginScopeRequest: RedirectRequest = {
    scopes: [Scope],
    // authority: authority,
  };