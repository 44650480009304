const Constants: { [key: string]: string } = {
  ATTEMPTFAILED1PIN: "Oops, the code is incorrect. Please try again.",
  ATTEMPTFAILED2PIN:
    "Still not right. You've got one attempt left. Please try again.",
  PROFILELOCKEDPIN: "Sorry, no attempts left. Please try again in 20 minutes.",
  ATTEMPTFAILED1QUESTION: "Oops, that answer's incorrect. Please try again.",
  ATTEMPTFAILED2QUESTION: "That's still not right.  Please have another go.",
  ATTEMPTFAILED3QUESTION:
    "Nope, that's still not right. This is your last go, after which you'll need to wait 20 min before trying again.",
  PROFILELOCKEDQUESTION:
    "Sorry, that was your last attempt. Please wait 20 minutes before having another go.",
  ATTEMPTFAILED1NUMBER: "The numbers don’t match. Please try again.",
  ATTEMPTFAILED2NUMBER:
    "This still isn’t right. If you don’t enter it correctly a third time, you’ll need to wait 20 minutes to have another go.",
  PROFILELOCKEDNUMBER:
    "Oh no, this was your last attempt, you’ll have to wait for 20 minutes before you can try again.",
  INVALIDACCOUNTNUMBER: "Sorry, we don’t recognise this account.",
  INVALIDUSERNAME:
    "Sorry, seems that this name doesn’t match with the account number.",
  INVALIDEMAIL:
    "Sorry, we don’t recognise this email. Please check and try again.",
  SAMEPASSWORDERROR:
    "You can’t use your previous password. Please enter a new one.",
  APIERRORMESSAGE:
    "Sorry, something’s wrong with the system. Please try again.",
  CONSUMERBAC:
    "Looks like you’re trying to recover the details for your home account?",
  VERIFICATIONEMAILSENTSUCCESSTITLE1:
    "The email is on its way. Please check your inbox.",
  VERIFICATIONEMAILSENTSUCCESSTITLE2: "The email is on its way.",
  VERIFICATIONEMAILSENTSUCCESSMESSAGE2:
    "If for some reason you don’t receive it, we can only send it one more time.",
  VERIFICATIONEMAILSENTSUCCESSTITLE3: "The email is on its way.",
  VERIFICATIONEMAILSENTSUCCESSMESSAGE3:
    "If you still don’t receive it, unfortunately we’re unable to verify you at this email address. Please try re-registering with a different one.",
  "": "",
};

export default Constants;
