import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ChangeCug,
  FetchClosedFaults,
  FetchOpenFaults,
} from "../../../../../app/redux/actions/repairCenter-action";
import { Selectbox } from "../../../../../bt-components";
import "./style.scss";

interface Props {
  data?: any;

  ChangeCug: any;
}

const CUGSelection = (props: Props) => {
  const [optiondata, setOptionData] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  useEffect(() => {
    let getUserData = props.data;
    if (getUserData && getUserData.isSuccess === true) {
      let groupData = getUserData.result && getUserData.result.Groups;
      setOptionData(groupData);
      setSelected(groupData[0]);
    }
  }, [props.data]);
  useEffect(() => {
    props.ChangeCug(selected);
  }, [selected]);
  return (
    <>
      <div
        className={`account-name-update ${
          optiondata.length > 1 ? "" : "one-account"
        }`}
      >
        <span className="bt-label bt-b">
          Select a group:
          <button className="i-btn bt-icon bt-info"></button>
          <span className="account-name-tooltip">
            <span className="tooltip-intro">
              <span>A group is made up of:</span>
              <ul className="bt-ul">
                <li className="bt-li">A set of billing accounts</li>
                <li className="bt-li">
                  Members with roles on those billing accounts
                </li>
                <li className="bt-li">
                  Any services charged to those billing accounts
                </li>
              </ul>
              <span>
                We set up groups for a business when it places an order.
              </span>
              <span>
                For example, a business may want a separate group for each
                department or location.
              </span>
              <span className="tool-tip-right-arrow"></span>
            </span>
          </span>
        </span>
        {optiondata.length > 1 ? (
          <Selectbox
            options={optiondata}
            selected={selected}
            onSelectedChange={setSelected}
            title={"Account name"}
          />
        ) : optiondata.length == 1 ? (
          <span className="open-modal-btn">{optiondata[0].Name}</span>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    selectedGroup: state.selectedGroup,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ChangeCug: (selectedGroup: any) => dispatch(ChangeCug(selectedGroup)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CUGSelection);
