import { useEffect, useState } from "react";
import { Click, Load } from "../../../../analytics/analytics";

import "./TechnicalErrorGrid.scss";


export default function TechnicalErrorPageGrid() {
  const [isGetInTouchModalOpen, setIsGetInTouchModalOpen] = useState(false);

  const toggleGetInTouchModal = () => {
    // document.body.classList.add("body-fixed", "enabledGetInTouchModal")
    document.body.classList.add("body-fixed")
    document.body.classList.add("enabledGetInTouchModal")
    Load({i: 136,p0: ""});
  };

  useEffect(() => {
    Load({
      i: 137,
    });


    window.scrollTo(0, 0);
  }, []);
  // handle refresh useeffect hook

  useEffect(() => {



    var entries: any = window.performance.getEntries();


    console.log(entries);
    console.log(entries[0].type)
    if (entries[0].type === "reload") {
      console.log(entries);
      console.log(entries[0].type);


      window.location.href = "../../";
    }





  }, []);

  useEffect(() => {
    //  document.getElementById("progressBar")?.remove();

    var child = document.getElementById("progressBar");
    if (child !== null && child.parentNode !== null) {
      child.parentNode.removeChild(child);
    }

  }, []);

  return (
    <>
      <div className="technical-error-cont-grid">
        <div className="verticalSpace1"></div>
        <div className="main-heading">
          <h1 className="pg-title">We're sorry. Something's gone wrong.</h1>
        </div>
        <div className="verticalSpace2"></div>
        <div className="errorText">
          <h2 className="sub-title">
            We’re working to fix this. It’s some kind of glitch.
          </h2>
        </div>
        <div className="verticalSpace3"></div>

        <div className="noticeText">
          <span className="bt-p notice-txt">
            The best thing to do is to leave it for the moment and try again in a
            while.
          </span>

          <span className="bt-p notice-txt">
            If that doesnt work, or you’d rather not wait, please{" "}
            <span
              className="bt-a cursor-p"
              onClick={() => {
                Click({ i: 137, m: "contact us" });
                toggleGetInTouchModal();
              }}
            >
              <u>contact us</u>
            </span>{" "}
            and we’ll try to help you.
          </span>

        </div>

        <div className="action-btn-wrp">
          <button
            type="submit"
            className="btn primary-btn"
            onClick={() => {
              Click({
                i: 137,
                m: 'Back to login',
              });
              window.location.href = "../../";
            }}
          >
            Back to log in
          </button>

        </div>
      </div>

    </>
  );
}
