import React from "react";

const AccountAccess = React.lazy(() => import('./AccountAccess'));

const AccountAccessRoute = {
    routeProps: {
        path: '/accountAccess',
        component: AccountAccess
    },
    name: "AccountAccess"
};

export default AccountAccessRoute;