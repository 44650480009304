import React from "react";

const RepairCentre= React.lazy(()=> import('./RepairCentre'))

export default {
  routeProps: {
    // path: "/Account/Dashboard#/RepairCentre",
    path: "/Account/RepairCentre",
    component: RepairCentre,
  },

  name: "RepairCentre",
};
