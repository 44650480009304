import LoginRouteDetails from './LoginDetails';
import AddAccountRoute from './AddAccount';
import AccountAccessRoute from "./AccountAccess";
import MyDetailsRoute from './MyDetails';
import Verification from './Verification';
import GenericErrorPage from './GenericErrorPage';
import ConsumerProfilepage from './ConsumerProfile';
import SessionTimeOutRoute from "./SessionExpired";

const selfRegistrationRoutes = [LoginRouteDetails, AddAccountRoute,AccountAccessRoute, MyDetailsRoute, Verification,GenericErrorPage,ConsumerProfilepage,SessionTimeOutRoute];

export default selfRegistrationRoutes;