import React from "react";

const ConsumerProfile = React.lazy(() => import('./ConsumerProfilepage'));

export default {
  routeProps: {
    path: "/ConsumerProfile",
    component: ConsumerProfile,
  },

  name: "ConsumerProfile",
};
