import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import Step from "./Step";
import "./ProgressBar.scss";
import { useState } from "react";
import StepNavigation from "./StepNavigation";

export default withRouter(observer(function ProgressBar(props: any) {

  const [currentStep, setCurrentStep] = useState(1);
  function updateStep(step: any) {
    setCurrentStep(step);
  }
  return (
    <>
      <div className="verticalSpace1"></div>

      <div className="progressBarGrid">
        <StepNavigation
          labelArray={props.labelArray}
          previousStep={props.previousStep}
          currentStep={currentStep}
          updateStep={updateStep}
          id={props.id}>
        </StepNavigation>
        {/* <p>Selected Step: {currentStep}</p>

      <button className="primaryButton" onClick={() => updateStep(currentStep - 1)}>Previous Button</button>

      <button className="primaryButton" onClick={() => updateStep(currentStep + 1)}>Next Button</button>
 */}

      </div>
    </>
  );


}))