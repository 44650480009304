import React from "react";

const GenericError = React.lazy(() => import('./GenericErrorPage'));

export default {
  routeProps: {
    path: "/GenericError",
    component: GenericError,
  },

  name: "GenericError",
};