import { combineReducers } from "redux";

import { displayError, fetchFaults, fetchStaticData, fetchUserDetails, getConfigAttributes, selectedGroup ,isDSPEnabledUser} from "./Repaircenter";

const rootReducer = combineReducers({
    fetchUserDetails,
    fetchStaticData,
    fetchFaults,
    selectedGroup,
    getConfigAttributes,
    isDSPEnabledUser,
    displayError
});

export default rootReducer;
