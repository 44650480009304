import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SelfRegistrationHeader from "../../../app/layout/SelfRegistrationHeader/SelfRegistrationHeader";
import { Preloader } from "../../../bt-components";
import ProgressBar from "../AssistedJourneyPages/ProgressBar/ProgressBar";
import selfRegistrationModules from "../SelfRegistrationJourneyPages/index";
import DecibelAnalytics from "../../../analytics/decibelAnalytics";

import './SelfRegistrationApp.scss';

const SelfRegistrationApp = () => {
  const labelArray = ["Login details", "Add account", "Account access", "My details", "Verification"]
  return (
    <div className="selfReg">
      <BrowserRouter basename="/registration">
        <div id="header-curve">
          <SelfRegistrationHeader />
        </div>
        {!window.location.pathname.includes("verification") && (
          <div id="progressBar" className="progressBar-grid">
            <ProgressBar labelArray={labelArray} id="self-reg"/>
          </div>
        )}
        <DecibelAnalytics />
        <Switch>
          <React.Suspense fallback={<Preloader />}>
            {selfRegistrationModules.map((componentWithRouting) => (
              <Route {...componentWithRouting.routeProps} key={componentWithRouting.name} exact />
            ))}
          </React.Suspense>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default SelfRegistrationApp;