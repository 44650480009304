import React, { useEffect, useState } from 'react'
import './style.scss';
import {HelpAndSupportCard} from "../../../../../bt-components"

interface CardObj {
    title: string;
    content: any;
    icon?: string;
    linkLabel?:string;
    link?:string;
    linkTarget?:string;
}
interface Props {
    cardsData:CardObj[];
}

const HelpAndSupportSection = (props:Props) => {
const [data, setData] = useState(props.cardsData);

    return (
        <>
            <div className="manage-services-wrp">
                <div className='cardsection'>
                    {data.map((card)=>(
                        <HelpAndSupportCard key={card.title} title={card.title} content={card.content} icon={card.icon} linkLabel={card.linkLabel} link={card.link} linkTarget={card.linkTarget}/>
                    ))}
               </div>
            </div>
        </>
    )
}

export default HelpAndSupportSection;