import React from "react";

const ReviewContractT2RPage = React.lazy(()=> import('./ReviewContractT2RPage'))

export default {
  routeProps: {
    path: "/EECCComplienceT2R/:documentId",
    component: ReviewContractT2RPage,
  },

  name: "ReviewContractT2R",
};
