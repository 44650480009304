
import "./SelfRegistrationHeader.scss";

const SelfRegistrationHeader = () => {
    return (
        <>
            <div className="header-cont">
                <div className="header-main-heading">
                    <div className="header-cnt bt-container">
                        <h3 className="header-pg-title">Business My Account: registration</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelfRegistrationHeader;