import React, { useState } from "react";
import "./Header.scss";

export default function Header() {
  return (
    <>
      <div className="header-cont">
        <div className="header-main-heading">
          <div className="header-cnt bt-container">
            <h3 className="header-pg-title">My Account: registration</h3>
          </div>
        </div>

        <div className="headerCurve">
          <img src={process.env.PUBLIC_URL + "/images/curve.svg"} className="img-desktop"/>
          <img src={process.env.PUBLIC_URL + '/images/curve_mobile.svg'} className="img-mobile"/>
          <img src={process.env.PUBLIC_URL + '/images/curve_tablet.svg'} className="img-tablet" />

        </div>
      </div>
    </>
  );
}
