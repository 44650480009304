import React from "react";

const GetPaperFreeFlag= React.lazy(() => import('./GetPaperFreeFlagPage'));

export default {
  routeProps: {
    path: '/VerifyEmail/:profileActivationCode',
    component: GetPaperFreeFlag,
  },

  name: "GetPaperFreeFlag",
};


