import { Route, Switch } from "react-router-dom";
import React from "react";
import { Preloader } from "../../../../bt-components/Preloader";
import DecibelAnalytics from "../../../../analytics/decibelAnalytics";
import emailNotVerifiedModules from "../EmailNotVerifiedPages/index";


function EmailNotVerifiedApp() {
  return (
    <>
        <DecibelAnalytics />
        <Switch>
          <React.Suspense fallback={<Preloader />}>
            {emailNotVerifiedModules.map((componentWithRouting) => (
              <Route {...componentWithRouting.routeProps} key={componentWithRouting.name} exact />
            ))}
          </React.Suspense>
        </Switch>
    </>
  );
}

export default EmailNotVerifiedApp;
