import { Route, Switch } from "react-router-dom";
import React from "react";
import { Preloader } from "../../../../bt-components/Preloader";
import DecibelAnalytics from "../../../../analytics/decibelAnalytics";
import verifyEmailModules from "../GetPaperFreeFlagPage/index";

function GetPaperFreeFlagApp() {
  return (
    <>
        <DecibelAnalytics />
        <Switch>
          <React.Suspense fallback={<Preloader />}>
            {verifyEmailModules.map((componentWithRouting) => (
              <Route {...componentWithRouting.routeProps} key={componentWithRouting.name} exact />
            ))}
          </React.Suspense>
        </Switch>
    </>
  );
}

export default GetPaperFreeFlagApp;
